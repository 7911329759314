import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const userValidationSchema = (intl: IntlShape) =>
  Yup.object({
    firstName: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'pages.login.form.errors.password.min',
            defaultMessage: 'Your fitst name must contain at least {min} characters',
          },
          { min: 3 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'pages.login.form.errors.first_name.min',
            defaultMessage: 'Your first name must contain at maximum {max} characters',
          },
          { max: 15 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'pages.account.form.errors.first_name.required',
          defaultMessage: 'Your first name is required',
        }),
      ),
    lastName: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.first_name.min',
            defaultMessage: 'Your last name must contain at least {min} characters',
          },
          { min: 3 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.last_name.min',
            defaultMessage: 'Your last name must contain at maximum {max} characters',
          },
          { max: 15 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'pages.account.form.errors.last_name.required',
          defaultMessage: 'Your last name is required',
        }),
      ),
    street: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.street.min',
            defaultMessage: 'Your street must contain at least {min} characters',
          },
          { min: 3 },
        ),
      )
      .max(
        25,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.street.max',
            defaultMessage: 'Your street must contain at maximum {max} characters',
          },
          { max: 25 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'pages.account.form.errors.street.required',
          defaultMessage: 'Your street is required',
        }),
      ),
    zipCode: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.zip_code.min',
            defaultMessage: 'Your zip code must contain at least {min} characters',
          },
          { min: 3 },
        ),
      )
      .max(
        8,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.zip_code.max',
            defaultMessage: 'Your zip code must contain at maximum {max} characters',
          },
          { max: 8 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'pages.account.form.errors.zip_code.required',
          defaultMessage: 'Your zip code is required',
        }),
      ),
    city: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.city.min',
            defaultMessage: 'Your city must contain at least {min} characters',
          },
          { min: 3 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.city.max',
            defaultMessage: 'Your city must contain at maximum {max} characters',
          },
          { max: 15 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'pages.account.form.errors.city.required',
          defaultMessage: 'Your city is required',
        }),
      ),
    country: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.country.min',
            defaultMessage: 'Your country must contain at least {min} characters',
          },
          { min: 3 },
        ),
      )
      .max(
        25,
        intl.formatMessage(
          {
            id: 'pages.account.form.errors.country.max',
            defaultMessage: 'Your country must contain at maximum {max} characters',
          },
          { max: 25 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'pages.account.form.errors.country.required',
          defaultMessage: 'Your country is required',
        }),
      ),
  });
