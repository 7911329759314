const statisticsFragment = `statistics {
  totalLinks
  totalLinksClicked
  totalLinksOpened
}`;

export const myApiKeys = (statistics = false) => `
  query MyApiKeys {
    myApiKeys {
      active
      alias
      id
      maxHitsPerHour
      maxHitsPerMinute
      maxLinksPerDay
      ${statistics ? statisticsFragment : ''}
      tags
    }
  }
`;
