export const updateUserInformation = `
  mutation UpdateMyInformation($input: UpdateMyInformationInput!) {
    updateMyInformation(input: $input) {
      city
      country
      firstName
      lastName
      street
      zipCode
    }
  }
`;
