import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../root';

interface Notification {
  defaultLabel: string;
  labelId: string;
  values?: any;
  type: 'error' | 'info' | 'success' | 'warning';
}

interface NotificationState {
  notification?: Notification;
  show?: boolean;
}

const initialState: NotificationState = {
  notification: undefined,
  show: false,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    hideNotification: (state, action: PayloadAction) => {
      state.notification = undefined;
      state.show = false;
    },
    showNotification: (state, action: PayloadAction<Notification>) => {
      state.notification = action.payload;
      state.show = true;
    },
  },
});

export const { hideNotification, showNotification } = notificationSlice.actions;
export const showNotificationSelector = (state: RootState) => (state.notifications.show ? state.notifications.notification : undefined);
export const showingNotificationSelector = (state: RootState) => state.notifications.show ?? false;
