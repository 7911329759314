import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ComposableMap, Geographies } from 'react-simple-maps';
import { Tooltip } from 'react-tooltip';
import { LinkCountry } from '../../../../models/link';
import ShrtLinkCountryRow from '../../../../pages/dashboard/api-key-statistics/table/ShrtLinkCountryRow';
import ShrtTable from '../../../common/Table';
import ShrtSectionTitle from '../../../common/typography/ShrtSectionTitle';
import ShrtLinkHeatMapCountry from './LinkHeatMapCountry';

const geoUrl = `${process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_APP_URL}/assets/topography/geography.json`;

type TooltipState = {
  code: string;
  count: number;
};

type ShrtLinkHeatMapProps = {
  countries?: LinkCountry[];
  max: number;
};
export default function ShrtLinkHeatMap({ countries, max }: ShrtLinkHeatMapProps) {
  const [countryTooltip, setCountryTooltip] = useState<TooltipState>({ code: '', count: 0 });
  const [highlighted, setHighlighted] = useState<string>();
  const values = countries?.map((c) => c.opened) ?? [];
  max ??= Math.max(...values);

  const handleCountryEnter = (linkCountry: LinkCountry) => {
    setCountryTooltip({ code: linkCountry.country, count: linkCountry.opened });
  };

  const handleCountryLeave = () => {
    setCountryTooltip({ code: '', count: 0 });
  };

  const handleCountryRowHover = (linkCountry: LinkCountry) => {
    setHighlighted(linkCountry.country);
  };

  const handleCountryRowHoverEnd = () => {
    setHighlighted(undefined);
  };

  return (
    <div className="dark:text-white">
      <ShrtSectionTitle labelId="pages.link.labels.heat_map_title" labelDefaultMessage="Distribution across countries" />
      <div className="grid xs:grid-cols-2 md:grid-cols-9 lg:grid-cols-12 gap-2">
        <div className="grid xs:col-span-1 md:col-span-3 lg:col-span-3 pt-28">
          <ShrtTable
            emptyDataDefaultLabel="No links opened so far"
            emptyDataLabel="pages.dashboard.api_key_statistics.labels.no_links_opened"
            headLabels={[<FormattedMessage id="common.country" defaultMessage="Country" />, <FormattedMessage id="common.opened" defaultMessage="Opened" />]}
            items={countries ?? []}
            renderRow={(linkCountry: LinkCountry) => (
              <ShrtLinkCountryRow
                key={linkCountry.country}
                linkCountry={linkCountry}
                hoverStartHandler={handleCountryRowHover}
                hoverEndHandler={handleCountryRowHoverEnd}
              />
            )}
          />
        </div>
        <div className="grid xs:col-span-1 md:col-span-6 lg:col-span-9">
          <ComposableMap>
            <Geographies geography={geoUrl} id="heatmap">
              {({ geographies }) =>
                geographies.map((geo) => {
                  const value = countries?.find((c) => c.country === geo.id);
                  return (
                    <ShrtLinkHeatMapCountry
                      country={value}
                      geo={geo}
                      key={geo.rsmKey}
                      max={max}
                      onCountryEnterHandler={handleCountryEnter}
                      onCountryLeaveHandler={handleCountryLeave}
                      selected={highlighted ? highlighted === value?.country : false}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
          <Tooltip anchorSelect={countryTooltip.code ? `#${countryTooltip.code}` : ''}>
            {countryTooltip.code && (
              <FormattedMessage
                id="pages.link.tooltips.country_opened_count"
                defaultMessage={`${countryTooltip.code}: ${countryTooltip.count}`}
                values={{
                  country: <FormattedMessage id={`countries.${countryTooltip.code}`} defaultMessage={countryTooltip.code} />,
                  value: countryTooltip.count,
                }}
              />
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
