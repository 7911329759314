import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const billingFreeTierValidationSchema = (intl: IntlShape) =>
  Yup.object({
    firstName: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters maximum',
          },
          { maximum: 15 },
        ),
      )
      .optional(),
    lastName: Yup.string().min(
      3,
      intl.formatMessage(
        {
          id: 'common.forms.validation.minimum',
          defaultMessage: '{minimum} characters minimum',
        },
        { minimum: 3 },
      ),
    ),
  });

export const billingValidationSchema = (intl: IntlShape) =>
  Yup.object({
    firstName: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters maximum',
          },
          { maximum: 15 },
        ),
      )
      .optional(),
    lastName: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters maximum',
          },
          { maximum: 15 },
        ),
      )
      .optional(),
    street: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        25,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters maximum',
          },
          { maximum: 25 },
        ),
      ),
    zipCode: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        8,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters maximum',
          },
          { maximum: 8 },
        ),
      ),
    city: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters maximum',
          },
          { maximum: 15 },
        ),
      ),
    country: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        25,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters maximum',
          },
          { maximum: 25 },
        ),
      ),
  });
