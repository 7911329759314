import { useTheme } from '@graphiql/react';
import { Button, useThemeMode } from 'flowbite-react';
import GraphiQL from 'graphiql';
import { useEffect } from 'react';
import { HiCheck } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShrSelectApiKey from '../../components/SelectApiKey';
import ShrtCallToActionCard from '../../components/common/CallToActionCard';
import ShrtSpinner from '../../components/common/Spinner';
import { Route } from '../../config/routes';
import { ApiKey } from '../../models/api-key';
import {
  apiKeyErrorSelector,
  apiKeysSelector,
  loadingApiKeysSelector,
  selectApiKey,
  selectedApiKeySelector,
} from '../../redux/reducers/api-keys/api-keys-reducer';
import { createAppSyncGraphiQLFetcher } from '../../services/appsync.graphql.service';
import { currentTheme } from '../../utils/ColorUtils';

const fetcher = (apiKey: string) => createAppSyncGraphiQLFetcher(apiKey);

export default function ShrtGraphiql() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedApiKey = useSelector(selectedApiKeySelector);
  const loadingApiKeys = useSelector(loadingApiKeysSelector);
  const hasError = useSelector(apiKeyErrorSelector);
  const myApiKeys = useSelector(apiKeysSelector);
  const selectedKey = selectedApiKey?.id ?? myApiKeys?.[0]?.id;
  const { setTheme } = useTheme();
  const { mode } = useThemeMode();

  useEffect(() => {
    setTheme(mode === 'auto' ? currentTheme() : mode);
  }, [mode, setTheme]);

  const handleOnClickApiKey: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    navigate(Route.Keys);
  };

  const handleSelectedApiKey = (key: ApiKey) => {
    dispatch(selectApiKey(key));
  };

  if (hasError) {
    return <span>An error occurred</span>;
  }
  if (loadingApiKeys) {
    return <ShrtSpinner />;
  }
  if (myApiKeys?.length === 0) {
    const description = <FormattedMessage id="pages.graphiql.description" defaultMessage="Just click on the button bellow to get one" />;
    const button = (
      <Button onClick={handleOnClickApiKey} outline>
        <HiCheck className="mr-2 h-5 w-5" />
        <FormattedMessage id="pages.graphiql.buttons.get_api_key" defaultMessage="Get my key" />
      </Button>
    );
    const title = <FormattedMessage id="pages.graphiql.title" defaultMessage="🔑 You don't have any key" />;
    return (
      <div className="w-full flex place-content-center mt-20">
        <ShrtCallToActionCard button={button} description={description} title={title} />
      </div>
    );
  }
  return (
    <div>
      <ShrSelectApiKey
        onSelectApiKeyHandler={handleSelectedApiKey}
        apiKeys={myApiKeys}
        labelId="pages.dashboard.labels.api_keys"
        labelDefaultMessage="Chose the API key you want to get insights for"
      />
      <div className="h-dvh w-full mt-4">
        {selectedKey && (
          <GraphiQL
            fetcher={fetcher(selectedKey)}
            defaultEditorToolsVisibility={false}
            disableTabs={true}
            isHeadersEditorEnabled={false}
            showPersistHeadersSettings={false}
          />
        )}
      </div>
    </div>
  );
}
