import { useQuery } from '@tanstack/react-query';
import { Button, Label, TextInput } from 'flowbite-react';
import { useFormik } from 'formik';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useShrtMutation } from '../../hooks/useShrtMutation';
import { getMyInformation, updateMyInformation } from '../../services/shortenify.service';
import { userValidationSchema } from './page_validation';

interface Profile {
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  street: string;
  zipCode: string;
}

export default function ShrtAccountSettings() {
  const intl = useIntl();
  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const response = await getMyInformation();
      return response.data.myInformation;
    },
    initialData: null,
  });
  const updateMyInformationMutation = useShrtMutation('user', updateMyInformation, 'updateMyInformation');
  const formik = useFormik<Profile>({
    initialValues: {
      city: user?.city ?? '',
      country: user?.country ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      street: user?.street ?? '',
      zipCode: user?.zipCode ?? '',
    },
    validationSchema: userValidationSchema(intl),
    onSubmit: async (values) =>
      updateMyInformationMutation.mutate({
        city: values.city,
        country: values.country,
        firstName: values.firstName,
        lastName: values.lastName,
        street: values.street,
        zipCode: values.zipCode,
      }),
  });

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" onSubmit={formik.handleSubmit}>
        <div>
          <Label htmlFor="firstName" value={intl.formatMessage({ id: 'pages.account.first_name', defaultMessage: 'First name' })} />
          <div className="mt-2">
            <TextInput
              id="firstName"
              type="text"
              autoComplete="billing given-name"
              placeholder={intl.formatMessage({
                id: 'pages.account.placeholders.first_name',
                defaultMessage: 'Type your first name',
              })}
              color={formik.touched.firstName && formik.errors.firstName ? 'failure' : undefined}
              helperText={formik.touched.firstName && formik.errors.firstName ? <span className="font-medium">{formik.errors.firstName}</span> : null}
              {...formik.getFieldProps('firstName')}
              required
            />
          </div>
        </div>
        <div>
          <Label htmlFor="lastName" value={intl.formatMessage({ id: 'pages.account.last_name', defaultMessage: 'Last name' })} />
          <div className="mt-2">
            <TextInput
              id="lastName"
              type="text"
              autoComplete="billing family-name"
              placeholder={intl.formatMessage({
                id: 'pages.account.placeholders.last_name',
                defaultMessage: 'Type your last name',
              })}
              color={formik.touched.lastName && formik.errors.lastName ? 'failure' : undefined}
              helperText={formik.touched.lastName && formik.errors.lastName ? <span className="font-medium">{formik.errors.lastName}</span> : null}
              {...formik.getFieldProps('lastName')}
              required
            />
          </div>
        </div>
        <div>
          <Label htmlFor="street" value={intl.formatMessage({ id: 'pages.account.labels.street', defaultMessage: 'Street' })} />
          <div className="mt-2">
            <TextInput
              id="street"
              type="text"
              autoComplete="billing street"
              placeholder={intl.formatMessage({
                id: 'pages.account.placeholders.street',
                defaultMessage: '11 Abbey Road',
              })}
              color={formik.touched.street && formik.errors.street ? 'failure' : undefined}
              helperText={formik.touched.street && formik.errors.street ? <span className="font-medium">{formik.errors.street}</span> : null}
              {...formik.getFieldProps('street')}
              required
            />
          </div>
        </div>
        <div>
          <Label htmlFor="zipCode" value={intl.formatMessage({ id: 'pages.account.labels.zip_code', defaultMessage: 'Zip code' })} />
          <div className="mt-2">
            <TextInput
              id="zipCode"
              type="text"
              autoComplete="billing zip code"
              placeholder={intl.formatMessage({
                id: 'pages.account.placeholders.zip_code',
                defaultMessage: 'Type your zip code',
              })}
              color={formik.touched.zipCode && formik.errors.zipCode ? 'failure' : undefined}
              helperText={formik.touched.zipCode && formik.errors.zipCode ? <span className="font-medium">{formik.errors.zipCode}</span> : null}
              {...formik.getFieldProps('zipCode')}
              required
            />
          </div>
        </div>
        <div>
          <Label htmlFor="city" value={intl.formatMessage({ id: 'pages.account.labels.city', defaultMessage: 'City' })} />
          <div className="mt-2">
            <TextInput
              id="city"
              type="text"
              autoComplete="billing city"
              placeholder={intl.formatMessage({
                id: 'pages.account.placeholders.city',
                defaultMessage: 'Type your city',
              })}
              color={formik.touched.city && formik.errors.city ? 'failure' : undefined}
              helperText={formik.touched.city && formik.errors.city ? <span className="font-medium">{formik.errors.city}</span> : null}
              {...formik.getFieldProps('city')}
              required
            />
          </div>
        </div>
        <div>
          <Label htmlFor="country" value={intl.formatMessage({ id: 'pages.account.labels.country', defaultMessage: 'Country' })} />
          <div className="mt-2">
            <TextInput
              id="country"
              type="text"
              autoComplete="billing country"
              placeholder={intl.formatMessage({
                id: 'pages.account.placeholders.country',
                defaultMessage: 'Type your country',
              })}
              color={formik.touched.country && formik.errors.country ? 'failure' : undefined}
              helperText={formik.touched.country && formik.errors.country ? <span className="font-medium">{formik.errors.country}</span> : null}
              {...formik.getFieldProps('country')}
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <Button className="flex" type="reset" color="gray" outline pill>
            <HiX className="mr-2 h-5 w-5" />
            <FormattedMessage id="common.buttons.cancel" defaultMessage="Cancel" />
          </Button>
          <Button className="flex" type="submit" outline pill disabled={formik.isSubmitting}>
            <HiCheck className="mr-2 h-5 w-5" />
            <FormattedMessage
              id={formik.isSubmitting ? 'common.buttons.saving' : 'common.buttons.save'}
              defaultMessage={formik.isSubmitting ? 'Saving...' : 'Save'}
            />
          </Button>
        </div>
      </form>
    </div>
  );
}
