export const linkSegmentsFragment = `fragment linkSegments on LinkSegments {
    countries {
      country
      opened
    }
    devices {
      OS {
        android
        iOS
      }
    }
    platforms {
      desktop
      mobile
      tablet
    }
  }`;
