import { useAuthenticator } from '@aws-amplify/ui-react';
import { Spinner } from 'flowbite-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import './App.css';
import ShrtContainer from './components/Container';
import ShrtFooter from './components/Footer';
import ShrtNavbar from './components/Navbar';
import { ShrtBreadcrumb } from './components/common/ShrtBreadcrumb';
import ShrtNotification from './components/common/ShrtNotification';
import { Route, getAuthFlowRouteFromPathname, isAuthFlowRoute } from './config/routes';
import ShrtForgotPassword from './pages/forgot-password/page';
import ShrtLogin from './pages/login/page';
import ShrtSignUp from './pages/sign-up/page';
import { loadMyInformation } from './redux/actions/userActions';
import { hideNotification, showNotificationSelector, showingNotificationSelector } from './redux/reducers/notifications/notification-reducer';
import { DEFAULT_NOTIFICATION_TIMEOUT_MS } from './utils/ConfigUtils';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

type LayoutProps = {
  location: string;
};

function Layout({ location }: LayoutProps) {
  const notification = useSelector(showNotificationSelector);
  const showingNotification = useSelector(showingNotificationSelector);
  const dispatch = useDispatch();

  const onDismissNotification = () => dispatch(hideNotification());

  useEffect(() => {
    const autoHideNotification = async () => {
      await sleep(DEFAULT_NOTIFICATION_TIMEOUT_MS);
      dispatch(hideNotification());
    };

    if (notification && showingNotification) {
      autoHideNotification();
    }
  }, [dispatch, notification, showingNotification]);

  return (
    <div className="h-full bg-white dark:bg-gray-800">
      <ShrtNotification
        labelId={notification?.labelId}
        defaultLabel={notification?.defaultLabel}
        onDismissHandler={onDismissNotification}
        show={!!notification}
        type={notification?.type}
        values={notification?.values}
      />
      <div className="sticky z-50 top-0 left-0 right-0">
        <ShrtNavbar />
      </div>
      <div className="pb-20 dark:bg-gray-800">
        <ShrtBreadcrumb location={location} />
        <ShrtContainer>
          <Outlet />
        </ShrtContainer>
      </div>
      <div className="fixed z-50 bottom-0 left-0 right-0 dark:bg-gray-800">
        <ShrtFooter />
      </div>
    </div>
  );
}

function getComponentFromRoute(route: Route) {
  if (route === Route.Login) {
    return <ShrtLogin />;
  }
  if (route === Route.ForgotPassword) {
    return <ShrtForgotPassword />;
  }
  if (route === Route.SignUp) {
    return <ShrtSignUp />;
  }
  return <ShrtLogin />;
}

function App() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const location = useLocation();
  const dispatch = useDispatch();
  console.log('authStatus:', authStatus);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      dispatch(loadMyInformation());
    }
  }, [authStatus, dispatch]);

  if (authStatus === 'configuring') {
    return (
      <div className="text-center">
        <Spinner aria-label="Loading session" />
      </div>
    );
  }
  if (authStatus === 'unauthenticated') {
    return isAuthFlowRoute(location.pathname) ? getComponentFromRoute(getAuthFlowRouteFromPathname(location.pathname)) : <ShrtLogin />;
  }
  return <Layout location={location.pathname} />;
}

export default App;
