import { Label, Select } from 'flowbite-react';
import { ChangeEventHandler, useState } from 'react';
import { useIntl } from 'react-intl';
import { ApiKey } from '../models/api-key';

type ShrtCallToActionCardProps = {
  apiKeys: ApiKey[];
  onSelectApiKeyHandler: (selectedApiKey: ApiKey) => void;
  labelId: string;
  labelDefaultMessage: string;
};

export default function ShrSelectApiKey({ apiKeys, labelId, labelDefaultMessage, onSelectApiKeyHandler }: ShrtCallToActionCardProps) {
  const intl = useIntl();
  const [apiKey, setApiKey] = useState<ApiKey>(apiKeys?.[0]);

  const handleSelectedApiKey: ChangeEventHandler<HTMLSelectElement> = (e) => {
    e.preventDefault();
    const selectedKey = apiKeys.find((k) => k.id === e.target.value)!;
    setApiKey(selectedKey);
    onSelectApiKeyHandler(selectedKey);
  };

  return (
    <div className="max-w-md">
      <div className="mb-2 block">
        <Label
          htmlFor="keys"
          value={intl.formatMessage({
            id: labelId,
            defaultMessage: labelDefaultMessage,
          })}
        />
      </div>
      <Select id="keys" onChange={handleSelectedApiKey} defaultValue={apiKey?.id}>
        {apiKeys.map((key) => (
          <option key={key.id} value={key.id}>
            {key.alias}&nbsp;({key.id})
          </option>
        ))}
      </Select>
    </div>
  );
}
