import { Button, Label, Select, TextInput } from 'flowbite-react';
import { Formik } from 'formik';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { PricingPlan, UserBilling } from '../../../models/user';
import { billingValidationSchema } from '../page_validation';

type ShrtPricingPlanFormProps = {
  billing?: UserBilling;
  onUpdateBilling: (values: any) => void;
  pricingPlan: PricingPlan;
};

export default function ShrtPricingPlanForm({ billing, onUpdateBilling, pricingPlan }: ShrtPricingPlanFormProps) {
  const intl = useIntl();
  const handleSubmit = (values: any) => {
    onUpdateBilling(values);
  };

  return (
    <Formik
      initialValues={{
        city: billing?.city,
        company: billing?.company,
        country: billing?.country,
        name: billing?.name,
        pricingPlan: billing?.pricingPlan ?? pricingPlan,
        street: billing?.street,
        zipCode: billing?.zipCode,
      }}
      onSubmit={handleSubmit}
      validationSchema={billingValidationSchema(intl)}
    >
      {(props) => (
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={props.handleSubmit}>
            <div>
              <Label htmlFor="pricingPlan" value={intl.formatMessage({ id: 'pages.billing.labels.plan', defaultMessage: 'Pricing plan' })} />
              <div className="mt-2">
                <Select {...props.getFieldProps('pricingPlan')}>
                  <option value={PricingPlan.Basic}>
                    <FormattedMessage id="common.pricing_plans.basic" defaultMessage="Basic plan" />
                  </option>
                  <option value={PricingPlan.Essential}>
                    <FormattedMessage id="common.pricing_plans.essential" defaultMessage="Essential plan" />
                  </option>
                  <option value={PricingPlan.Premium}>
                    <FormattedMessage id="common.pricing_plans.premium" defaultMessage="Premium plan" />
                  </option>
                </Select>
              </div>
            </div>
            {billing?.company && (
              <div>
                <Label htmlFor="company" value={intl.formatMessage({ id: 'pages.billing.labels.company', defaultMessage: 'Company' })} />
                <div className="mt-2">
                  <TextInput
                    id="company"
                    type="text"
                    autoComplete="billing company"
                    placeholder={intl.formatMessage({
                      id: 'pages.billing.placeholders.company',
                      defaultMessage: 'Type your company',
                    })}
                    {...props.getFieldProps('company')}
                  />
                </div>
              </div>
            )}
            <div>
              <Label htmlFor="name" value={intl.formatMessage({ id: 'pages.billing.labels.name', defaultMessage: 'First name' })} />
              <div className="mt-2">
                <TextInput
                  id="name"
                  type="text"
                  autoComplete="billing given-name"
                  placeholder={intl.formatMessage({
                    id: 'pages.billing.placeholders.name',
                    defaultMessage: 'Type the full name of the payer',
                  })}
                  {...props.getFieldProps('name')}
                  disabled={true}
                />
              </div>
            </div>
            <div>
              <div>
                <Label htmlFor="street" value={intl.formatMessage({ id: 'pages.account.labels.street', defaultMessage: 'Street' })} />
                <div className="mt-2">
                  <TextInput
                    id="street"
                    type="text"
                    autoComplete="billing street"
                    placeholder={intl.formatMessage({
                      id: 'pages.account.placeholders.street',
                      defaultMessage: '11 Abbey Road',
                    })}
                    {...props.getFieldProps('street')}
                    disabled={true}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="zipCode" value={intl.formatMessage({ id: 'pages.account.labels.zip_code', defaultMessage: 'Zip code' })} />
                <div className="mt-2">
                  <TextInput
                    id="zipCode"
                    type="text"
                    autoComplete="billing zip code"
                    placeholder={intl.formatMessage({
                      id: 'pages.account.placeholders.zip_code',
                      defaultMessage: 'Type your zip code',
                    })}
                    {...props.getFieldProps('zipCode')}
                    disabled={true}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="city" value={intl.formatMessage({ id: 'pages.account.labels.city', defaultMessage: 'City' })} />
                <div className="mt-2">
                  <TextInput
                    id="city"
                    type="text"
                    autoComplete="billing city"
                    placeholder={intl.formatMessage({
                      id: 'pages.account.placeholders.city',
                      defaultMessage: 'Type your city',
                    })}
                    {...props.getFieldProps('city')}
                    disabled={true}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="country" value={intl.formatMessage({ id: 'pages.account.labels.country', defaultMessage: 'Country' })} />
                <div className="mt-2">
                  <TextInput
                    id="country"
                    type="text"
                    autoComplete="billing country"
                    placeholder={intl.formatMessage({
                      id: 'pages.account.placeholders.country',
                      defaultMessage: 'Type your country',
                    })}
                    {...props.getFieldProps('country')}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 mt-10">
                <Button className="flex" type="reset" color="gray" outline pill>
                  <HiX className="mr-2 h-5 w-5" />
                  <FormattedMessage id="pages.billing.buttons.cancel_pay" defaultMessage="Cancel my plan" />
                </Button>
                <Button className="flex" type="submit" outline pill disabled={props.values.pricingPlan === billing?.pricingPlan || props.isSubmitting}>
                  <HiCheck className="mr-2 h-5 w-5" />
                  <FormattedMessage
                    id={props.isSubmitting ? 'pages.billing.buttons.updating_plan' : 'pages.billing.buttons.update_plan'}
                    defaultMessage={props.isSubmitting ? 'Updating my plan' : 'Update my plan'}
                  />
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
}
