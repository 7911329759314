export enum Route {
  AccountBilling = '/billing',
  AccountSettings = '/account',
  Dashboard = '/dashboard',
  DashboardApiKey = ':apiKey',
  DashboardLink = '/dashboard/:apiKey/links/:linkId',
  DashboardLinks = '/dashboard/:apiKey/links',
  ForgotPassword = '/forgot',
  Graphiql = '/graphiql',
  Home = '/',
  Login = '/login',
  Keys = '/keys',
  SignUp = '/join',
}

export const BREADCRUMB_ROUTES = ['all', 'dashboard', 'graphiql', 'links', 'settings'];

export const getAuthFlowRouteFromPathname = (pathname: string) => {
  if (pathname === Route.ForgotPassword.valueOf()) {
    return Route.ForgotPassword;
  }
  if (pathname === Route.SignUp.valueOf()) {
    return Route.SignUp;
  }
  return Route.Login;
};

export const isAuthFlowRoute = (route: string) => [Route.ForgotPassword.valueOf(), Route.SignUp.valueOf()].includes(route);
