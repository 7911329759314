import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const loginValidationSchema = (intl: IntlShape) =>
  Yup.object({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'common.forms.validation.email',
          defaultMessage: 'Not a valid email',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
    password: Yup.string()
      .min(
        8,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 8 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
  });
