import { Footer } from 'flowbite-react';

export default function ShrtFooter() {
  return (
    <Footer container>
      <div className="w-full sm:flex sm:justify-center space-x-4">
        <Footer.Copyright href="#" by="Shortenify™" year={2024} />
      </div>
    </Footer>
  );
}
