export const createLink = `
  mutation Shortenify($input: ShortenifyInput!) {
    shortenify(input: $input) {
      created
      id
      opened
      redirectTo
      url
    }
  }
`;
