import { Button, Label, TextInput } from 'flowbite-react';
import { useState } from 'react';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConfirmSignUpParams, handleResendSignUpCode } from '../../../services/authentication.service';

type ConfirmSignUpModalState = {
  code: string;
  sendingCode: boolean;
};

type ConfirmSignUpModalProps = {
  cancel: () => void;
  confirm: (input: ConfirmSignUpParams) => void;
  email: string;
};

export default function ConfirmSignUpModal({ cancel, confirm, email }: ConfirmSignUpModalProps) {
  const intl = useIntl();
  const [state, setState] = useState<ConfirmSignUpModalState>({
    code: '',
    sendingCode: false,
  });

  const handleOnSendAnotherCode = async () => {
    setState({ ...state, sendingCode: true });
    await handleResendSignUpCode(email);
    setState({ ...state, sendingCode: false });
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    return confirm({ code: state.code, username: email });
  };

  return (
    <form className="space-y-6" onSubmit={handleOnSubmit}>
      <div>
        <div className="mb-2 block">
          <Label htmlFor="alias" value="Alias" />
        </div>
        <TextInput
          id="code"
          placeholder={intl.formatMessage({
            id: 'pages.sign_up.modal.placeholders.button',
            defaultMessage: 'Enter the code received by email',
          })}
          value={state.code}
          onChange={(event) => setState({ ...state, code: event.target.value })}
          required
        />
      </div>
      <div className="flex justify-center gap-4">
        <Button color="gray" onClick={handleOnSendAnotherCode} disabled={state.sendingCode}>
          <HiX className="mr-2 h-5 w-5" />
          <FormattedMessage
            id={state.sendingCode ? 'pages.sign_up.modal.buttons.sending_another_code' : 'pages.sign_up.modal.buttons.send_another_code'}
            defaultMessage={state.sendingCode ? 'Sending...' : 'Send another code'}
          />
        </Button>
        <Button color="success" type="submit">
          <HiCheck className="mr-2 h-5 w-5" />
          <FormattedMessage id="pages.sign_up.modal.buttons.confirm" defaultMessage="Complete sign up" />
        </Button>
      </div>
    </form>
  );
}
