import { Pie } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { generateRandomColors } from '../../../utils/ColorUtils';

type ShrtLinkPieChartProps = {
  defaultTitle: string;
  labels: string[];
  title: string;
  values: number[];
};

export default function ShrtLinkPieChart({ defaultTitle, labels, title, values }: ShrtLinkPieChartProps) {
  const intl = useIntl();
  const translatedTitle = intl.formatMessage({
    id: `pages.link.labels.${title}`,
    defaultMessage: defaultTitle,
  });
  const data = {
    labels,
    datasets: [
      {
        label: translatedTitle,
        data: values,
        backgroundColor: generateRandomColors(values.length),
        hoverOffset: 4,
      },
    ],
  };
  return (
    <div className="align-center text-center">
      <h5 className="text-gray-500 dark:text-white">{translatedTitle}</h5>
      <Pie data={data} className="text-gray-500 dark:text-white" />
    </div>
  );
}
