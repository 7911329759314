import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { createClient } from 'graphql-ws';
import { v4 as uuidv4 } from 'uuid';
import { GraphqlWebSocket } from '../models/graphql-websocket';

const WS_URL = process.env.REACT_APP_REAL_TIME_PUBLIC_ENDPOINT!;
const HOST = process.env.REACT_APP_REAL_TIME_PUBLIC_DOMAIN!;

export const createAppSyncGraphiQLFetcher = (apiKey: string) => {
  const header = btoa(`{"Authorization":"${apiKey}","host":"${HOST}"}`);
  const url = `${WS_URL}?header=${header}&payload=e30=`;
  return createGraphiQLFetcher({
    headers: {
      Authorization: apiKey,
      host: HOST,
    },
    url: process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT!,
    wsClient: createClient({
      lazy: true,
      keepAlive: 0,
      url,
      jsonMessageReviver: function (key: string, value: any) {
        /**
         * As {"type": "ka"} and {"type": "start_ack"} messages are not supported by the client,
         * and as we don't need any confirmation on client side,
         * we juste pretend it's a well-known {"type": "connection_ack"} message
         */
        if (key === 'type' && (value === 'ka' || value === 'start_ack')) {
          this['id'] = uuidv4(); // random id needed for validation
          return 'connection_ack';
        }
        if (key === 'type' && value === 'data') {
          // As {"type": "data"} message is not supported by the client, we simulate it as {"type": "next"} message
          return 'next';
        }
        if (key === 'type' && value === 'error' && this.payload['errors']) {
          // As {"payload": {"errors": []}} is not supported by the client
          this.payload = this.payload['errors'];
          return value;
        }
        return value;
      },
      jsonMessageReplacer: function (this: any, key: string, value: any) {
        if (key === 'type' && value === 'subscribe') {
          this.payload['data'] = JSON.stringify({ query: this.payload.query });
          this.payload['extensions'] = {
            authorization: {
              Authorization: apiKey,
              host: HOST,
            },
          };
          // As {"type": "subscribe"} message is not supported by AppSync, we map it to a {"type": "start"} message
          return 'start';
        }
        return value;
      },
      webSocketImpl: GraphqlWebSocket,
    }),
  });
};
