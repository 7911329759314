export enum PricingPlan {
  Basic = 'basic',
  Essential = 'essential',
  FreeTier = 'freeTier',
  Premium = 'premium',
}

export type UserBilling = {
  city: string;
  company: string;
  country: string;
  expirationDate: string;
  name: string;
  pricingPlan: PricingPlan;
  street: string;
  zipCode: string;
};

export type User = {
  billing: UserBilling;
  city: string;
  country: string;
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  street: string;
  zipCode: string;
};
