import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import { HiOutlineTrash, HiPlus } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ShrtApiKeyCard from '../../components/ApiKeyCard';
import ShrtSpinner from '../../components/common/Spinner';
import { useShrtMutation } from '../../hooks/useShrtMutation';
import { ApiKey } from '../../models/api-key';
import { apiKeysSelector, createdApiKey, deletedApiKey, loadingApiKeysSelector, setApiKeyError } from '../../redux/reducers/api-keys/api-keys-reducer';
import { showNotification } from '../../redux/reducers/notifications/notification-reducer';
import { CreateApiKeyInput, createMyApiKey, deleteMyApiKey } from '../../services/shortenify.service';
import CreateApiKeyModal from './modal/modal-create';
import DeleteApiKeyModal from './modal/modal-delete';

type ModalType = 'CREATE' | 'DELETE';
interface ShrtSettingsState {
  apiKey?: string;
  openModal: boolean;
  type?: ModalType;
}

export default function ShrtSettings() {
  const dispatch = useDispatch();
  const loading = useSelector(loadingApiKeysSelector);
  const keys = useSelector(apiKeysSelector);
  const [state, setState] = useState<ShrtSettingsState>({ openModal: false });
  const dispatchApiKey = (apiKey: ApiKey) => dispatch(createdApiKey(apiKey));
  const dispatchDeletedApiKey = (id: string) => dispatch(deletedApiKey(id));
  const dispatchCreationError = (e: Error, values: {}) => {
    dispatch(setApiKeyError(e?.message ?? e?.name));
    dispatch(
      showNotification({
        defaultLabel: 'We could not create your API key with tag <strong>{tag}</strong>',
        labelId: 'pages.settings.notifications.create_api_error',
        type: 'error',
        values: {
          ...values,
          strong: (chunks: string) => <b>{chunks}</b>,
        },
      }),
    );
  };
  const dispatchDeleteError = (e: Error, values: {}) => {
    dispatch(setApiKeyError(e?.message ?? e?.name));
    dispatch(
      showNotification({
        defaultLabel: 'We could not delete your API key',
        labelId: 'pages.settings.notifications.delete_api_error',
        type: 'error',
      }),
    );
  };
  const createApiKeyMutation = useShrtMutation('myApiKeys', createMyApiKey, 'createApiKey', dispatchApiKey, dispatchCreationError);
  const deleteApiKeyMutation = useShrtMutation('myApiKeys', deleteMyApiKey, 'deleteApiKey', dispatchDeletedApiKey, dispatchDeleteError);

  const handleCreateApiKey = () => {
    setState({ openModal: true, type: 'CREATE' });
  };

  const handleDeleteApiKey = (apiKey: string) => {
    setState({ apiKey, openModal: true, type: 'DELETE' });
  };

  const handleCloseApiKeyModal = () => {
    setState({ openModal: false });
  };

  const handleOnCancel = () => {
    setState({ openModal: false });
  };

  const handleOnConfirm = (input: CreateApiKeyInput) => {
    createApiKeyMutation.mutate(input);
    setState({ openModal: false });
  };

  const handleOnConfirmDelete = async () => {
    deleteApiKeyMutation.mutate({ apiKey: state.apiKey });
    setState({ openModal: false });
    if (state.apiKey) {
      dispatch(deletedApiKey(state.apiKey));
    }
  };

  if (loading) {
    return <ShrtSpinner />;
  }

  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-1 col-end-6">
          <Button outline onClick={handleCreateApiKey} type="submit">
            <HiPlus className="mr-2 h-5 w-5" />
            <FormattedMessage id="pages.settings.buttons.create_api_key" defaultMessage="Get an API key" />
          </Button>
        </div>
        <Modal show={state.openModal} size="lg" onClose={handleCloseApiKeyModal} popup>
          <Modal.Header>
            {state.type === 'CREATE' && <FormattedMessage id="pages.settings.modal.titles.create" defaultMessage="API key information" />}
            {state.type === 'DELETE' && <FormattedMessage id="pages.settings.modal.titles.delete" defaultMessage="Delete" />}
          </Modal.Header>
          <Modal.Body>
            {state.type === 'CREATE' && <CreateApiKeyModal cancel={handleOnCancel} confirm={handleOnConfirm} />}
            {state.type === 'DELETE' && <DeleteApiKeyModal cancel={handleOnCancel} confirm={handleOnConfirmDelete} />}
          </Modal.Body>
        </Modal>
      </div>
      <div className="my-8 grid grid-cols-4 gap-4">
        {keys.map((k: ApiKey) => (
          <div className="xl:col-span-1 lg:col-span-2 sm:col-span-2 xs:col-span-4" key={k.id}>
            <ShrtApiKeyCard apiKey={k} key={k.id}>
              <Button outline color="failure" onClick={() => handleDeleteApiKey(k.id)} type="submit">
                <HiOutlineTrash className="mr-2 h-5 w-5" />
                <FormattedMessage id="pages.settings.buttons.delete_api_key" defaultMessage="Delete" />
              </Button>
            </ShrtApiKeyCard>
          </div>
        ))}
      </div>
    </div>
  );
}
