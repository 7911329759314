import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const createLinkValidationSchema = (intl: IntlShape) =>
  Yup.object({
    url: Yup.string()
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      )
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        intl.formatMessage({
          id: 'common.forms.validation.url',
          defaultMessage: 'Invalid HTTP link',
        }),
      ),
  });
