import { Card } from 'flowbite-react';
import { ReactNode } from 'react';

type ShrtCallToActionCardProps = {
  button: ReactNode;
  description: ReactNode;
  title: ReactNode;
};

export default function ShrtCallToActionCard({ button, description, title }: ShrtCallToActionCardProps) {
  return (
    <Card className="max-w-md">
      <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
      <p className="font-normal text-gray-700 dark:text-gray-400">{description}</p>
      {button}
    </Card>
  );
}
