import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const createApiKeyValidationSchema = (intl: IntlShape) =>
  Yup.object({
    alias: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        25,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters maximum',
          },
          { maximum: 25 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
    tag: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        25,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters maximum',
          },
          { maximum: 25 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
  });
