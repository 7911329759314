import { LinkStatisticsPeriod } from '../../models/link';
import { linkSegmentsFragment } from '../fragments/link.segments';

const getStatisticsFragment = (from?: string, to?: string, period?: LinkStatisticsPeriod) => {
  if (from && to && period) {
    return `statistics(period: ${period}, start: "${from}", end: "${to}") {
      items {
        date
        opened
      }
      nextToken
      totalCount
    }`;
  }
  return '';
};

export const getLink = (apiKey: string, id: string, from?: string, to?: string, period?: LinkStatisticsPeriod) => `
query GetLink {
  getLink(apiKey: "${apiKey}", id: "${id}") {
      created
      id
      lastOpened
      opened
      qrCode {
        type
        value
      }
      redirectTo
      segments {
        ...linkSegments
      }
      ${getStatisticsFragment(from, to, period)}
      url
  }
}
${linkSegmentsFragment}
`;
