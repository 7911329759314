import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { apiKeysSlice } from './reducers/api-keys/api-keys-reducer';
import { notificationSlice } from './reducers/notifications/notification-reducer';
import { myInformationSlice } from './reducers/user/user-reducer';
import rootSaga from './sagas/root-saga';

// Build the middleware for intercepting and dispatching navigation actions
// const middleware = routerMiddleware(history);
// Build the saga middleware
const sagaMiddleware = createSagaMiddleware({
  //   onError: error => ErrorService.captureError(error),
});

// const middlewares = [sagaMiddleware];
// const enhancers = [applyMiddleware(...middlewares)];
// const composedEnhancers = composeWithDevTools(...enhancers);

export const store = configureStore({
  reducer: {
    apiKeys: apiKeysSlice.reducer,
    user: myInformationSlice.reducer,
    notifications: notificationSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
