export const createApiKey = `
  mutation CreateApiKey($input: CreateApiKeyInput!) {
    createApiKey(input: $input) {
      id
      active
      alias
      maxHitsPerHour
      maxHitsPerMinute
      maxLinksPerDay
      ownerId
      tags
    }
  }
`;
