import { Button } from 'flowbite-react';
import { useEffect } from 'react';
import { HiCheck } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ShrtApiKeyStatisticsCard from '../../components/ApiKeyStatisticsCard';
import ShrtCallToActionCard from '../../components/common/CallToActionCard';
import ShrtQuery from '../../components/common/ShrtQuery';
import ShrtSpinner from '../../components/common/Spinner';
import { Route } from '../../config/routes';
import { ApiKey } from '../../models/api-key';
import {
  apiKeysByIdSelector,
  loadingApiKeysSelector,
  selectApiKey,
  selectedApiKeySelector,
  setApiKeyError,
  setApiKeys,
} from '../../redux/reducers/api-keys/api-keys-reducer';
import { getMyApiKeys } from '../../services/shortenify.service';

export default function ShrtDashboard() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const selectedApiKey = useSelector(selectedApiKeySelector) ?? null;
  const [, , apiKeyParam] = pathname.split('/');
  const loadingApiKeys = useSelector(loadingApiKeysSelector);
  const myApiKeys = useSelector(apiKeysByIdSelector);

  const handleOnClickApiKey: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    navigate(Route.Keys);
  };

  const handleShowMoreForApiKey = (apiKey: ApiKey) => {
    dispatch(selectApiKey(apiKey));
    navigate(`${Route.Dashboard}/${apiKey.id}`, { state: { apiKey } });
  };

  const definedApiKeys = (keys: ApiKey[]) => dispatch(setApiKeys(keys));

  useEffect(() => {
    const selectApiKeyFromUrl = (apiKeyParam: string) => {
      if (myApiKeys) {
        const apiKey = myApiKeys[apiKeyParam];
        if (!apiKey) {
          dispatch(setApiKeyError('pages.dashboard.errors.invalid_api_key'));
        } else {
          dispatch(selectApiKey(apiKey));
        }
      }
    };
    if (selectedApiKey === null && apiKeyParam) {
      selectApiKeyFromUrl(apiKeyParam);
    }
  }, [apiKeyParam, dispatch, myApiKeys, selectedApiKey]);

  if (loadingApiKeys) {
    return <ShrtSpinner />;
  }

  if (Object.keys(myApiKeys ?? {}).length === 0) {
    const description = <FormattedMessage id="pages.graphiql.description" defaultMessage="Just click on the button bellow to get one" />;
    const button = (
      <Button onClick={handleOnClickApiKey} outline>
        <HiCheck className="mr-2 h-5 w-5" />
        <FormattedMessage id="pages.graphiql.buttons.get_api_key" defaultMessage="Get my key" />
      </Button>
    );
    const title = <FormattedMessage id="pages.graphiql.title" defaultMessage="🔑 You don't have any key" />;
    return (
      <div className="w-full flex place-content-center mt-20">
        <ShrtCallToActionCard button={button} description={description} title={title} />
      </div>
    );
  }

  return (
    <div className="min-h-full">
      <ShrtQuery action={definedApiKeys} initialData={[]} query={() => getMyApiKeys(true)} queryKey="myApiKeys">
        {(data) => {
          return (
            <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-4">
              {data?.myApiKeys?.map((apiKey: ApiKey) => (
                <div key={apiKey.id}>
                  <ShrtApiKeyStatisticsCard active={pathname.includes(apiKey.id)} apiKey={apiKey} onShowMoreHandler={handleShowMoreForApiKey} />
                </div>
              ))}
            </div>
          );
        }}
      </ShrtQuery>
      <div className="xs:mt-4 sm:mt-8 lg:mt-10">
        <Outlet />
      </div>
    </div>
  );
}
