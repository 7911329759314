import { BREADCRUMB_ROUTES } from '../config/routes';
import { ApiKey } from '../models/api-key';

/**
 *
 * @param params The URLSearchParams object to parse
 * @param name The name of the parameter to look for
 * @param transform The transformation function to apply if provided, the string value of the parameter otherwise
 * @param fallback The fallback value if needed
 * @returns
 */
export function getParam<T>(params: URLSearchParams, name: string, transform: (x: string) => T, fallback: T): T {
  const param = params.get(name);
  if (param === null) {
    return fallback;
  }
  return transform(param);
}

export const extractBreadcrumbItems = (location: string, selectedApiKey: ApiKey | null) => {
  const [, ...items] = location.split('/');
  const hierarchy = [{ name: 'home', value: '/' }];
  let name: string | undefined = undefined;
  let value: string;
  items.forEach((hierarchicalItem) => {
    if (BREADCRUMB_ROUTES.includes(hierarchicalItem)) {
      if (name) {
        hierarchy.push({ name, value });
      }
      name = hierarchicalItem;
      value = name;
    } else if (selectedApiKey !== null && hierarchicalItem === selectedApiKey?.id) {
      if (name) {
        hierarchy.push({ name, value });
      }
      value += `/${selectedApiKey.id}`;
      name = selectedApiKey.alias ?? hierarchicalItem;
    } else {
      value += `/${hierarchicalItem}`;
    }
  });
  return hierarchy;
};
