import { confirmSignUp, resendSignUpCode, signIn, signOut, signUp } from 'aws-amplify/auth';

export type ConfirmSignUpParams = {
  code: string;
  username: string;
};

type SignUpParams = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

type SignInParams = {
  email: string;
  password: string;
};

export async function handleConfirmSignUp({ code, username }: ConfirmSignUpParams) {
  return confirmSignUp({ username, confirmationCode: code });
}

export async function handleSignUp({ email: username, firstName, lastName, password }: SignUpParams) {
  return signUp({
    username,
    password,
    options: {
      autoSignIn: true,
      userAttributes: {
        email: username,
        given_name: firstName,
        family_name: lastName,
      },
    },
  });
}

export async function handleResendSignUpCode(email: string) {
  return resendSignUpCode({ username: email });
}

export async function signInUser({ email: username, password }: SignInParams) {
  return signIn({ username, password });
}

export async function signOutUser() {
  await signOut();
}
