import { Button, Label, TextInput } from 'flowbite-react';
import { useFormik } from 'formik';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { deleteApiKeyValidationSchema } from './modal-delete_validation';

type DeleteteApiKeyModalProps = {
  cancel: () => void;
  confirm: () => void;
};

interface DeleteteApiKeyInput {
  confirmation: string;
}

export default function DeleteApiKeyModal({ cancel, confirm }: DeleteteApiKeyModalProps) {
  const intl = useIntl();
  const label = intl.formatMessage({ id: 'pages.settings.modal.labels.delete', defaultMessage: 'You must confirm by typing "delete":' });
  const placeholder = intl.formatMessage({ id: 'pages.settings.modal.placeholders.delete', defaultMessage: 'Yes, delete' });

  const formik = useFormik<DeleteteApiKeyInput>({
    initialValues: {
      confirmation: '',
    },
    validationSchema: deleteApiKeyValidationSchema(intl),
    onSubmit: () => confirm(),
  });

  const handleOnCancel = () => cancel();

  return (
    <form className="space-y-6" onSubmit={formik.handleSubmit}>
      <h5>
        <FormattedMessage
          id="pages.settings.modal.descriptions.delete"
          defaultMessage="Are you sure you want to delete this API key? This action is irreversible."
        />
      </h5>
      <div>
        <div className="mb-2 block">
          <Label htmlFor="confirmation" value={label} />
        </div>
        <TextInput
          id="confirmation"
          placeholder={placeholder}
          color={formik.touched.confirmation && formik.errors.confirmation ? 'failure' : undefined}
          helperText={formik.touched.confirmation && formik.errors.confirmation ? <span className="font-medium">{formik.errors.confirmation}</span> : null}
          {...formik.getFieldProps('confirmation')}
          autoComplete="off"
        />
      </div>
      <div className="flex justify-center gap-4">
        <Button color="gray" onClick={handleOnCancel}>
          <HiX className="mr-2 h-5 w-5" />
          <FormattedMessage id="pages.settings.modal.buttons.cancel" defaultMessage="Cancel" />
        </Button>
        <Button color="failure" type="submit">
          <HiCheck className="mr-2 h-5 w-5" />
          <FormattedMessage id="pages.settings.modal.buttons.delete" defaultMessage="Yes, delete" />
        </Button>
      </div>
    </form>
  );
}
