import { Toast } from 'flowbite-react';
import { PropsWithChildren } from 'react';
import { HiCheck, HiExclamation, HiInformationCircle, HiX } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';

interface ShrtNotificationProps {
  defaultLabel?: string;
  labelId?: string;
  onDismissHandler: () => void;
  show?: boolean;
  type?: 'error' | 'info' | 'success' | 'warning';
  values?: any;
}

export default function ShrtNotification({ defaultLabel, labelId, onDismissHandler, show = false, type, values }: PropsWithChildren<ShrtNotificationProps>) {
  console.info('values: ', values);
  return (
    <div>
      {show && (
        <Toast className="absolute z-50 top-20 right-5">
          {type === 'error' && (
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
              <HiX className="h-5 w-5" />
            </div>
          )}
          {type === 'info' && (
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-cyan-200">
              <HiInformationCircle className="h-5 w-5" />
            </div>
          )}
          {type === 'success' && (
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
              <HiCheck className="h-5 w-5" />
            </div>
          )}
          {type === 'warning' && (
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
              <HiExclamation className="h-5 w-5" />
            </div>
          )}
          <div className="ml-3 text-sm font-normal">
            {defaultLabel && labelId && <FormattedMessage defaultMessage={defaultLabel} id={labelId} values={values} />}
          </div>
          <Toast.Toggle onDismiss={onDismissHandler} />
        </Toast>
      )}
    </div>
  );
}
