import { FormattedMessage } from 'react-intl';

type ShrtSectionTitleProps = {
  className?: string;
  labelDefaultMessage: string;
  labelId: string;
};

export default function ShrtSectionTitle({ className, labelDefaultMessage, labelId }: ShrtSectionTitleProps) {
  return (
    <div className={`flex justify-center text-xl text-cyan-600 font-semibold dark:text-white ${className ?? ''}`}>
      <FormattedMessage id={labelId} defaultMessage={labelDefaultMessage} />
    </div>
  );
}
