export const userBillingFragment = `fragment userBilling on User {
    billing {
      city
      company
      country
      expirationDate
      name
      pricingPlan
      street
      zipCode
    }
  }`;
