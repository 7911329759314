import { Badge, Card, Kbd, Tooltip } from 'flowbite-react';
import { ReactNode, useState } from 'react';
import {
  HiExternalLink,
  HiOutlineDuplicate,
  HiOutlineExclamationCircle,
  HiOutlineKey,
  HiOutlineShieldCheck,
  HiOutlineShieldExclamation,
  HiOutlineTag,
} from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApiKey } from '../models/api-key';

type ShrtApiKeyCardProps = {
  apiKey: ApiKey;
  children?: React.ReactNode;
};

type ShrtApiKeyCardItemProps = {
  description: ReactNode;
  itemId: string;
  leftIcon: ReactNode;
  title: string | ReactNode;
  rightIcon?: ReactNode;
};

function ShrtApiKeyCardItem({ description, itemId, leftIcon, rightIcon, title }: ShrtApiKeyCardItemProps) {
  return (
    <li className="py-3 sm:py-4" key={itemId}>
      <div className="flex items-center space-x-4">
        <div className="shrink-0">{leftIcon}</div>
        <div className="min-w-0 flex-1">
          <p className="truncate text-sm font-medium text-gray-900 dark:text-white">{title}</p>
          <p className="truncate text-sm text-gray-500 dark:text-gray-400">{description}</p>
        </div>
        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">{rightIcon}</div>
      </div>
    </li>
  );
}

export default function ShrtApiKeyCard({ apiKey, children }: ShrtApiKeyCardProps) {
  const intl = useIntl();
  const [isCopied, setIsCopied] = useState(false);
  const { active, alias, id, maxHitsPerHour, maxHitsPerMinute, maxLinksPerDay, tags } = apiKey;

  async function copyApiKeyToClipboard(apiKey: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(apiKey);
    } else {
      return document.execCommand('copy', true, apiKey);
    }
  }

  const handleCopyApiKey = async (id: string) => {
    await copyApiKeyToClipboard(id);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div className="col-span-1 xs:col-span-1">
      <Card className="max-w-md">
        <div className="mb-4 flex items-center justify-between">
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">{alias}</h5>
          <Badge color={active ? 'success' : 'warning'} icon={active ? HiOutlineShieldCheck : HiOutlineShieldExclamation} />
        </div>
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            <ShrtApiKeyCardItem
              title={id}
              description={<FormattedMessage id="pages.settings.cards.descriptions.api_key" defaultMessage="To put in 'Authorization' header" />}
              itemId="api_key"
              leftIcon={<HiOutlineKey />}
              rightIcon={
                <Tooltip
                  content={
                    <span>
                      {isCopied ? (
                        <FormattedMessage id="pages.settings.tooltips.copied_api_key" defaultMessage="Copied" />
                      ) : (
                        <FormattedMessage id="pages.settings.tooltips.copy_api_key" defaultMessage="Copy" />
                      )}
                    </span>
                  }
                >
                  <HiOutlineDuplicate className="cursor-pointer" onClick={() => handleCopyApiKey(id)} />
                </Tooltip>
              }
            />
            <ShrtApiKeyCardItem
              title={<FormattedMessage id="pages.settings.cards.descriptions.tags" defaultMessage="Tags" />}
              description={tags?.map((tag) => (
                <Kbd key={tag}>{tag === '' ? intl.formatMessage({ id: 'pages.settings.tooltips.no_tag', defaultMessage: 'No tag' }) : tag}</Kbd>
              ))}
              itemId="tags"
              leftIcon={<HiOutlineTag />}
            />
            <ShrtApiKeyCardItem
              title={<FormattedMessage id="pages.settings.cards.titles.maxLinksPerDay" defaultMessage="{links} links/day" values={{ links: maxLinksPerDay }} />}
              description={
                <FormattedMessage
                  id="pages.settings.cards.descriptions.maxLinksPerDay"
                  defaultMessage="Up to {links} links generated for one day"
                  values={{ links: maxLinksPerDay }}
                />
              }
              itemId="links_per_day"
              leftIcon={<HiExternalLink />}
            />
            <ShrtApiKeyCardItem
              title={<FormattedMessage id="pages.settings.cards.titles.maxHitsPerHour" defaultMessage="{hits} hits/hour" values={{ hits: maxHitsPerHour }} />}
              description={<FormattedMessage id="pages.settings.cards.descriptions.maxHitsPerHour" defaultMessage="Maximum hits per hour" />}
              itemId="hits_per_hour"
              leftIcon={<HiOutlineExclamationCircle />}
            />
            <ShrtApiKeyCardItem
              title={
                <FormattedMessage id="pages.settings.cards.titles.maxHitsPerMinute" defaultMessage="{hits} hits/min" values={{ hits: maxHitsPerMinute }} />
              }
              description={<FormattedMessage id="pages.settings.cards.descriptions.maxHitsPerMinute" defaultMessage="Maximum hits per minute" />}
              itemId="hits_per_minute"
              leftIcon={<HiOutlineExclamationCircle />}
            />
          </ul>
        </div>
        {children}
      </Card>
    </div>
  );
}
