export const Colors = {
  PRIMARY: 'bg-sky-500 hover:bg-sky-600',
};

export const BackgroundColors = {
  Container: 'bg-white dark:bg-gray-800',
  NavBar: 'bg-white dark:bg-gray-800',
};
export type BackgroundColorType = keyof typeof BackgroundColors;
export type BackgroundColor = (typeof BackgroundColors)[BackgroundColorType];

export const GraphColors: { [k: string]: { CLASS: string; RGB: string; HEX?: string } } = {
  AMBER: { CLASS: 'text-amber-400 dark:text-amber-400', RGB: 'rgb(251 191 36)' },
  BLUE: { CLASS: 'text-blue-400 dark:text-blue-400', RGB: 'rgb(96 165 250)' },
  CYAN: { CLASS: 'text-cyan-400 dark:text-cyan-400', RGB: 'rgb(34 211 238)' },
  FUSHIA: { CLASS: 'text-fushia-400 dark:text-fushia-400', RGB: 'rgb(232 121 249)' },
  GREEN: { CLASS: 'text-green-400 dark:text-green-400', RGB: 'rgb(74 222 128)' },
  INDIGO: { CLASS: 'text-indigo-400 dark:text-indigo-400', RGB: 'rgb(129 140 248)' },
  LIME: { CLASS: 'text-lime-400 dark:text-lime-400', RGB: 'rgb(163 230 53)' },
  ORANGE: { CLASS: 'text-orange-400 dark:text-orange-400', RGB: 'rgb(251 146 60)' },
  PINK: { CLASS: 'text-pink-400 dark:text-pink-400', RGB: 'rgb(244 114 182)' },
  PURPLE: { CLASS: 'text-purple-400 dark:text-purple-400', RGB: 'rgb(192 132 252)' },
  RED: { CLASS: 'text-red-400 dark:text-red-400', RGB: 'rgb(248 113 113)' },
  ROSE: { CLASS: 'text-rose-400 dark:text-rose-400', RGB: 'rgb(251 113 133)' },
  SKY: { CLASS: 'text-sky-400 dark:text-sky-400', RGB: 'rgb(56 189 248)', HEX: '#38BDF8' },
  VIOLET: { CLASS: 'text-violet-400 dark:text-violet-400', RGB: 'rgb(167 139 250)' },
  YELLOW: { CLASS: 'text-yellow-400 dark:text-yellow-400', RGB: 'rgb(250 204 21)' },
};

const randomNumber = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min);

export const generateRandomColors = (size: number) => {
  const sample = Object.keys(GraphColors).map((k) => GraphColors[k].RGB);
  const colors: string[] = [];
  while (colors.length < size) {
    const index = randomNumber(0, sample.length - 1);
    const color = sample[index];
    if (!colors.includes(color)) {
      colors.push(color);
    }
  }
  return colors;
};

export const currentTheme = () => (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
