import { resendSignUpCode } from 'aws-amplify/auth';
import { Button, Label, Modal } from 'flowbite-react';
import { Formik } from 'formik';
import { useState } from 'react';
import { HiLogin } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/logo_no_background.png';
import ShrtNotification from '../../components/common/ShrtNotification';
import ShrtTextInput from '../../components/common/ShrtTextInput';
import { Route } from '../../config/routes';
import { showNotification } from '../../redux/reducers/notifications/notification-reducer';
import { ConfirmSignUpParams, handleConfirmSignUp, signInUser } from '../../services/authentication.service';
import { getPricingPlan } from '../../services/features.service';
import ConfirmSignUpModal from '../sign-up/modal/modal';
import { loginValidationSchema } from './page_validation';

type Credentials = {
  email: string;
  password: string;
};

export default function ShrtLogin() {
  const intl = useIntl();
  const { pathname } = useLocation();
  let [searchParams] = useSearchParams({});
  const plan = searchParams.get('plan') ? getPricingPlan() : undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleCloseConfirmSignUpModal = () => {
    setOpenModal(false);
  };

  const handleOnCancel = () => {
    setOpenModal(false);
  };

  const handleOnConfirm = async (input: ConfirmSignUpParams) => {
    try {
      const { isSignUpComplete, nextStep } = await handleConfirmSignUp(input);
      if (isSignUpComplete) {
        setOpenModal(false);
        navigate(Route.Home);
      } else {
        // TODO check next step and display alerts
      }
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  };

  const handleOnSubmit = async (values: Credentials) => {
    // TODO Handle error here (wrong email/password error for instance)
    try {
      const { isSignedIn, nextStep } = await signInUser(values);
      if (!isSignedIn) {
        if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
          await resendSignUpCode({ username: values.email });
          setOpenModal(true);
        }
      } else {
        if (pathname === Route.AccountBilling && searchParams?.get('plan')) {
          navigate(`${Route.AccountBilling}?plan=${searchParams?.get('plan')}`);
        } else {
          dispatch(showNotification({ defaultLabel: 'Welcome back', labelId: 'common.notifications.welcome_back', type: 'info' }));
          navigate(Route.Home);
        }
      }
    } catch (e) {
      setShowError(true);
    }
  };

  return (
    <div>
      <ShrtNotification
        labelId="pages.login.notifications.invalid_credentials"
        defaultLabel="Email and/or password are wrong"
        onDismissHandler={() => setShowError(false)}
        show={showError}
        type="warning"
      />
      <Formik initialValues={{ email: '', password: '' }} onSubmit={handleOnSubmit} validationSchema={loginValidationSchema(intl)}>
        {(props) => (
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img className="mx-auto h-10 w-auto" src={logo} alt="shortenify" />
              {/* <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                <FormattedMessage id="pages.login.labels.title" defaultMessage="Sign in" />
              </h2> */}
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={props.handleSubmit}>
                <div>
                  <Label htmlFor="email" value="Email" />
                  <div className="mt-2">
                    <ShrtTextInput
                      autoComplete="off"
                      defaultPlaceholder="your_email@gmail.com"
                      formikProps={props}
                      id="email"
                      placeholderId="common.forms.placeholders.email"
                      type="email"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <Label htmlFor="password" value="Password" />
                    <div className="text-sm">
                      <Link to={Route.ForgotPassword} className="font-semibold text-indigo-600 hover:text-indigo-500">
                        <FormattedMessage id="pages.login.labels.forgot_password" defaultMessage="Forgot password?" />
                      </Link>
                    </div>
                  </div>
                  <div className="mt-2">
                    <ShrtTextInput autoComplete="off" formikProps={props} id="password" type="password" />
                  </div>
                </div>
                <div>
                  <Button className="flex w-full" type="submit" outline>
                    <HiLogin />
                    &nbsp;
                    <FormattedMessage id="pages.login.buttons.confirm" defaultMessage="Sign in" />
                  </Button>
                </div>
              </form>
              <p className="mt-10 text-center text-sm text-gray-500">
                <FormattedMessage
                  id="pages.login.labels.register"
                  defaultMessage="Sign up (no credit card required)"
                  values={{
                    link: (chunks) => (
                      <Link to={`${Route.SignUp}${plan ? `?plan=${plan}` : ''}`} className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                        {chunks}
                      </Link>
                    ),
                  }}
                />
              </p>
            </div>
            <Modal show={openModal} size="md" onClose={handleCloseConfirmSignUpModal} popup>
              <Modal.Header>
                <FormattedMessage id="pages.login.modal.title" defaultMessage="Confirmation" />
              </Modal.Header>
              <Modal.Body>
                <ConfirmSignUpModal cancel={handleOnCancel} confirm={handleOnConfirm} email={props.values.email} />
              </Modal.Body>
            </Modal>
          </div>
        )}
      </Formik>
    </div>
  );
}
