import { useInfiniteQuery } from '@tanstack/react-query';
import { Alert, Button } from 'flowbite-react';
import { HiOutlineCollection } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import ShrtSpinner from './Spinner';

type UseShrtinfiniteQueryProps = {
  apiKey: string;
  children: (data: any) => JSX.Element; //React.ReactNode | JSX.Element | null | undefined;
  errorLabelId?: string;
  initialData?: any;
  placeholderData?: any;
  query: Function;
  queryKey: string;
  queryKeyCache?: string;
  queryKeyResponse?: string;
  refetchInterval?: number;
};

export default function ShrtInfiniteQuery({
  children,
  errorLabelId,
  query,
  queryKey,
  queryKeyCache = queryKey,
  queryKeyResponse = queryKey,
}: UseShrtinfiniteQueryProps) {
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
    queryKey: [queryKeyCache],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const response = await query(pageParam);
      return response.data;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage, pages) => lastPage?.[queryKeyResponse].nextToken,
  });

  const handleLoadMore = () => fetchNextPage();

  return status === 'pending' || (isFetching && !isFetchingNextPage) ? (
    <ShrtSpinner />
  ) : status === 'error' ? (
    <div className="flex justify-center">
      <Alert color="warning">
        <FormattedMessage id={errorLabelId ? errorLabelId : 'common.generic_error_api'} defaultMessage="An error occurred. Please try again later..." />
      </Alert>
    </div>
  ) : (
    <>
      {children(data.pages.flatMap((p) => p?.[queryKeyResponse].items ?? []) ?? [])}
      <div className="flex justify-center mt-20">
        <Button outline onClick={handleLoadMore} disabled={!hasNextPage || isFetchingNextPage}>
          <HiOutlineCollection className="mr-2 h-5 w-5" />
          {isFetchingNextPage ? (
            <FormattedMessage id="pages.dashboard.all_links.buttons.loading_more" defaultMessage="Loading more..." />
          ) : hasNextPage ? (
            <FormattedMessage id="pages.dashboard.all_links.buttons.load_more" defaultMessage="Load more" />
          ) : (
            <FormattedMessage id="pages.dashboard.all_links.buttons.loading_over" defaultMessage="Nothing more to load" />
          )}
        </Button>
      </div>
    </>
  );
}
