import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../../models/user';
import { RootState } from '../../root';

// Define a type for the slice state
interface UserState {
  ui: {
    error?: string;
    loading: boolean;
  };
  myInformation?: User;
}

// Define the initial state using that type
const initialState: UserState = {
  ui: {
    error: undefined,
    loading: false,
  },
  myInformation: undefined,
};

export const myInformationSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadMyInformation: (state) => {
      state.ui.loading = true;
      state.ui.error = undefined;
    },
    setMyInformation: (state, action: PayloadAction<User>) => {
      state.myInformation = action.payload;
      state.ui.loading = false;
      state.ui.error = undefined;
    },
  },
});

export const { loadMyInformation, setMyInformation } = myInformationSlice.actions;
export const myInformationSelector = (state: RootState) => state.user.myInformation;
export const pricingPlanSelector = (state: RootState) => state.user.myInformation?.billing?.pricingPlan;
// UI
export const myInformationErrorSelector = (state: RootState) => state.user.ui.error;
export const loadingMyInformationSelector = (state: RootState) => state.user.ui.loading;
