import { useMutation, useQueryClient } from '@tanstack/react-query';

type ErrorHandler = (error: Error, variables: any, context: any) => Promise<unknown> | unknown;
type SuccessHandler = (data: any, variables: any, context: any) => Promise<unknown> | unknown;

export const useShrtMutation = (queryKey: string, mutation: Function, field: string, successHandler?: SuccessHandler, errorHandler?: ErrorHandler) => {
  // Access the client
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (input: any) => {
      const response = await mutation(input);
      const newValue = response?.data?.[field];
      return newValue;
    },
    // When mutate is called:
    onMutate: async (newValue) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [queryKey] });
      // Snapshot the previous value
      const previousValues = queryClient.getQueryData([queryKey]);
      // Optimistically update to the new value
      if (previousValues) {
        queryClient.setQueryData([queryKey], (old: any) => [...(old[queryKey] ?? []), newValue]);
      }
      // Return a context object with the snapshotted value
      return { previousValues };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, variables, context) => {
      console.error('Error saving record:', err, variables);
      if (context?.previousValues) {
        queryClient.setQueryData([queryKey], context.previousValues);
      }
      errorHandler?.(err, variables, context);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
    onSuccess: successHandler,
  });
};
