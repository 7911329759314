import { Feature } from '../models/feature';
import { PricingPlan } from '../models/user';

const featurePlans: { [key: string]: Feature[] } = {
  [PricingPlan.Basic.valueOf()]: [],
  [PricingPlan.Essential.valueOf()]: [Feature.Graphiql, Feature.ProtectedLink, Feature.Subscriptions],
  [PricingPlan.Premium.valueOf()]: [Feature.Graphiql, Feature.QrCode, Feature.ProtectedLink, Feature.Subscriptions],
};

export const getPricingPlan = (plan = 'essential') => {
  if (plan === 'basic') {
    return PricingPlan.Basic;
  }
  if (plan === 'essential') {
    return PricingPlan.Essential;
  }
  if (plan === 'premium') {
    return PricingPlan.Premium;
  }
};

export const getFeatures = (plan: PricingPlan) => featurePlans[plan];

export const hasFeature = (plan: PricingPlan, feature: Feature) => {
  const available = getFeatures(plan)?.includes(feature);
  return available;
};
