import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import ShrtQuery from '../../../../components/common/ShrtQuery';
import ShrtSpinner from '../../../../components/common/Spinner';
import ShrtLinkDetails from '../../../../components/links/ShrtLinkDetails';
import { ApiKey } from '../../../../models/api-key';
import { Link as ApiLink, LinkStatisticsPeriod } from '../../../../models/link';
import { apiKeysSelector, selectApiKey, selectedApiKeySelector } from '../../../../redux/reducers/api-keys/api-keys-reducer';
import { getMyLink } from '../../../../services/shortenify.service';
import { formatDay, isAfter, isBefore, parseDay, startOfWeek, today } from '../../../../utils/DateUtils';
import { getParam } from '../../../../utils/UrlUtils';

const transformDate = (date: string): Date => parseDay(date).toDate();

type ShrtLinkState = {
  error: boolean;
  fromDate: Date;
  link?: ApiLink;
  linkParam: string;
  refresh: boolean;
  toDate: Date;
};

export default function ShrtLink() {
  const dispatch = useDispatch();
  const location = useLocation();
  let initialFromDate = startOfWeek(true);
  const initialToDate = today();
  // Case where the user comes from dashboard by clicking on "More statistics" button
  const passedDownLink = location.state?.link?.id;
  let [searchParams, setSearchParams] = useSearchParams({
    from: formatDay(initialFromDate),
    to: formatDay(initialToDate),
  });
  const [, , apiKeyParam, , linkParam] = location.pathname.split('/');
  const [state, setState] = useState<ShrtLinkState>({
    error: false,
    fromDate: getParam<Date>(searchParams, 'from', transformDate, initialFromDate),
    linkParam: passedDownLink ?? linkParam,
    refresh: true,
    toDate: getParam<Date>(searchParams, 'to', transformDate, initialToDate),
  });
  const myApiKeys = useSelector(apiKeysSelector);
  const selectedApiKey = useSelector(selectedApiKeySelector);
  const { fromDate, toDate } = state;

  useEffect(() => {
    const validateApiKey = async (apiKeys: ApiKey[]) => {
      const existingApiKey = apiKeys?.find((key) => key.id === apiKeyParam);
      if (existingApiKey?.id && selectedApiKey === null) {
        dispatch(selectApiKey(existingApiKey));
        setState((state) => ({ ...state, refresh: true }));
      }
    };
    if (!selectedApiKey) {
      validateApiKey(myApiKeys);
    }
  }, [apiKeyParam, dispatch, myApiKeys, selectedApiKey]);

  const handleOnChangeFromDate = (date: Date) => {
    if (!isAfter(date, state.toDate)) {
      setState((state) => ({ ...state, fromDate: date, refresh: true }));
      setSearchParams({ from: formatDay(date), to: formatDay(state.toDate) });
    }
  };

  const handleOnChangeToDate = (date: Date) => {
    if (!isBefore(date, state.fromDate)) {
      setState((state) => ({ ...state, refresh: true, toDate: date }));
      setSearchParams({ from: formatDay(state.fromDate), to: formatDay(date) });
    }
  };

  // Case of a refresh on that page
  if (!selectedApiKey) {
    return <ShrtSpinner />;
  }

  return (
    <ShrtQuery
      initialData={undefined}
      errorLabelId="pages.link.errors.link_not_found"
      query={() => getMyLink(selectedApiKey.id, state.linkParam, formatDay(fromDate), formatDay(toDate), LinkStatisticsPeriod.Daily)}
      queryKey={`link#${state.linkParam}#${fromDate.toISOString()}#${toDate.toISOString()}`}
    >
      {(data) => (
        <ShrtLinkDetails
          fromDate={fromDate}
          initialFromDate={initialFromDate}
          initialToDate={initialToDate}
          link={data.getLink}
          onChangeFromDate={handleOnChangeFromDate}
          onChangeToDate={handleOnChangeToDate}
          toDate={toDate}
        />
      )}
    </ShrtQuery>
  );
}
