import { userBillingFragment } from '../fragments/user.billing';

export const myInformation = `
  query myInformation {
    myInformation {
      ...userBilling
      city
      country
      email
      id
      firstName
      lastName
      street
      zipCode
    }
  }
  ${userBillingFragment}
`;
