import moment from 'moment';
import { IntlShape } from 'react-intl';

const WEEK_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const DAY_FORMAT = 'YYYY-MM-DD';
const MONTH_FORMAT = 'YYYY-MM';

export const formatDay = (date: Date | string, format?: string) => moment(date).format(format ?? DAY_FORMAT);
export const formatMonth = (date: Date | string, format?: string) => moment(date).format(format ?? MONTH_FORMAT);

export const parseDate = (date: string) => moment(date).toDate();
export const parseDay = (date: string) => moment(date, DAY_FORMAT);
export const parseMonth = (date: string) => moment(date, MONTH_FORMAT);
export const startOfWeek = (previousWeekIfMonday = false) => {
  let startOfCurrentWeek = moment().startOf('isoWeek');
  if (!previousWeekIfMonday) {
    return startOfCurrentWeek.toDate();
  }
  if (startOfCurrentWeek.isSame(moment(), 'day')) {
    return startOfCurrentWeek.subtract(1, 'day').startOf('isoWeek').toDate();
  }
  return startOfCurrentWeek.toDate();
};
export const today = () => moment().toDate();
export const isAfter = (date: Date, otherDate: Date) => moment(date).isAfter(otherDate);
export const isBefore = (date: Date, otherDate: Date) => moment(date).isBefore(otherDate);
export const weekDays = (intl: IntlShape) =>
  WEEK_DAYS.map((day) => intl.formatMessage({ id: `common.weeks.short_days.${day}`, defaultMessage: day.substring(0, 2) }));
export const timeTo = (date: string) => moment(date).diff(moment(), 'days');
