import { Datepicker } from 'flowbite-react';
import { FormattedMessage } from 'react-intl';
import { Link as ApiLink, LinkStatisticsPeriod } from '../../models/link';
import { formatDay, parseDate } from '../../utils/DateUtils';
import ShrtSectionTitle from '../common/typography/ShrtSectionTitle';
import ShrtLinkPieChart from '../statistics/links/LinkPieChart';
import ShrtLinkQRCode from '../statistics/links/LinkQRCode';
import ShrtLinkQuickStatistics from '../statistics/links/LinkQuickStatistic';
import ShrtLinkStatisticsLineChart from '../statistics/links/LinkStatisticsLineChart';
import ShrtLinkHeatMap from '../statistics/links/heat-map/LinkHeatMap';

type ShrtLinkDetailsProps = {
  fromDate: Date;
  onChangeFromDate: (date: Date) => void;
  onChangeToDate: (date: Date) => void;
  initialFromDate: Date;
  initialToDate: Date;
  link: ApiLink;
  toDate: Date;
};

export default function ShrtLinkDetails({ fromDate, initialFromDate, initialToDate, link, onChangeFromDate, onChangeToDate, toDate }: ShrtLinkDetailsProps) {
  const handleOnChangeFromDate = (date: Date) => onChangeFromDate(date);

  const handleOnChangeToDate = (date: Date) => onChangeToDate(date);

  return (
    <div className="flex flex-col space-y-20">
      <div className="grid xs:grid-cols-1 sm:grid-cols-3 lg:grid-cols-6 divide-x">
        {link.qrCode && (
          <ShrtLinkQRCode
            fontColor="text-grey-500 dark:text-white"
            labelDefaultMessage="QR code"
            labelId="pages.link.labels.qr_code"
            value={link.qrCode.value}
          />
        )}
        <ShrtLinkQuickStatistics fontColor="text-grey-500 dark:text-white" labelDefaultMessage="ID" labelId="pages.link.labels.id" value={link.id ?? '--'} />
        <ShrtLinkQuickStatistics
          fontColor="text-grey-500 dark:text-white"
          labelDefaultMessage="Link"
          labelId="pages.link.labels.link"
          value={link.url.replace(/https?:\/\//, '') ?? '--'}
          url={link.url ?? '--'}
        />
        <ShrtLinkQuickStatistics fontColor="text-green-500" labelDefaultMessage="Total" labelId="pages.link.labels.opened_total" value={link.opened ?? '--'} />
        <ShrtLinkQuickStatistics
          fontColor="text-grey-500 dark:text-white"
          labelDefaultMessage="Created on"
          labelId="pages.link.labels.created_on"
          value={link.created ? formatDay(link.created, 'll') : '--'}
        />
        <ShrtLinkQuickStatistics
          fontColor="text-grey-500 dark:text-white"
          labelDefaultMessage="Last opened"
          labelId="pages.link.labels.last_opened"
          value={link.lastOpened ? formatDay(link.lastOpened, 'lll') : '--'}
        />
      </div>
      <div>
        <ShrtSectionTitle className="mb-10" labelId="pages.link.labels.opened_chart_title" labelDefaultMessage="Daily clicks" />
        <div className="flex flew-row gap-x-8">
          <div className="flex flex-col space-y-1">
            <div className="text-grey-500 dark:text-white">
              <FormattedMessage id="pages.link.labels.statistics_from" defaultMessage="From:" />
            </div>
            <Datepicker
              defaultDate={fromDate ?? initialFromDate}
              minDate={link.created ? parseDate(link.created) : new Date()}
              maxDate={new Date()}
              weekStart={1}
              onSelectedDateChanged={handleOnChangeFromDate}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <div className="text-grey-500 dark:text-white">
              <FormattedMessage id="pages.link.labels.statistics_to" defaultMessage="To:" />
            </div>
            <Datepicker defaultDate={toDate ?? initialToDate} autoHide={true} maxDate={new Date()} weekStart={1} onSelectedDateChanged={handleOnChangeToDate} />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="grid xs:grid-cols-1 sm:grid-cols-6 md:grid-cols-12">
          {link.statistics?.items && (
            <div className="xs:col-span-1 sm:col-span-4 md:col-span-10 md:col-start-2">
              <ShrtLinkStatisticsLineChart
                statistics={link.statistics.items}
                from={formatDay(fromDate)}
                to={formatDay(toDate)}
                period={LinkStatisticsPeriod.Daily}
              />
            </div>
          )}
        </div>
      </div>
      {link && (
        <div>
          <ShrtSectionTitle className="mb-10" labelId="pages.link.labels.pie_charts_title" labelDefaultMessage="Distributions across devices/OS" />
          <div className="grid xs:grid-cols-2 sm:grid-cols-6 md:grid-cols-12">
            <div className="xs:col-span-1 sm:col-span-3 md:col-span-3 md:col-start-4">
              <ShrtLinkPieChart
                defaultTitle="Platforms"
                title="platform_charts_title"
                labels={['desktop', 'mobile', 'tablet']}
                values={[link.segments?.platforms.desktop ?? 0, link.segments?.platforms.mobile ?? 0, link.segments?.platforms.tablet ?? 0]}
              />
            </div>
            <div className="xs:col-span-1 sm:col-span-3 md:col-span-3">
              <ShrtLinkPieChart
                defaultTitle="Devices"
                title="devices_os_charts_title"
                labels={['android', 'iOS']}
                values={[link.segments?.devices.OS?.android ?? 0, link.segments?.devices.OS?.iOS ?? 0]}
              />
            </div>
          </div>
        </div>
      )}
      <div className="mt-10">
        <ShrtLinkHeatMap countries={link.segments?.countries} max={link.opened ?? 100} />
      </div>
    </div>
  );
}
