import { Amplify } from 'aws-amplify';
import config from './aws-config';

export default function configure() {
  console.info('Using config:', config);
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.userPoolId,
        userPoolClientId: config.userPoolWebClientId,
      },
    },
    API: {
      GraphQL: {
        endpoint: config.graphql.endpoint,
        defaultAuthMode: 'userPool',
        region: config.region,
      },
    },
  });
}
