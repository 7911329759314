import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const deleteApiKeyValidationSchema = (intl: IntlShape) =>
  Yup.object({
    confirmation: Yup.string()
      .equals(
        [
          intl.formatMessage({
            id: 'pages.settings.modal.placeholders.delete',
            defaultMessage: 'Yes, delete',
          }),
        ],
        intl.formatMessage({
          id: 'pages.settings.modal.errors.confirmation',
          defaultMessage: 'You must confirm by typing "Yes, delete"',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
  });
