import { Button, Label, TextInput } from 'flowbite-react';
import { useFormik } from 'formik';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { deleteLinkValidationSchema } from './validation/delete-modal_validation';

type DeleteLinkModalProps = {
  cancel: () => void;
  confirm: () => void;
};

interface DeleteteApiKeyInput {
  confirmation: string;
}

export default function DeleteLinkModal({ cancel, confirm }: DeleteLinkModalProps) {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: 'pages.dashboard.api_key_statistics.modal.labels.confirm_delete',
    defaultMessage: 'Please confirm by typing "delete":',
  });
  const placeholder = intl.formatMessage({ id: 'pages.dashboard.api_key_statistics.modal.placeholders.delete', defaultMessage: 'delete' });

  const formik = useFormik<DeleteteApiKeyInput>({
    initialValues: {
      confirmation: '',
    },
    validationSchema: deleteLinkValidationSchema(intl),
    onSubmit: () => confirm(),
  });

  const handleOnCancel = () => cancel();

  return (
    <form className="space-y-6" onSubmit={formik.handleSubmit}>
      <p className="text-gray-500 dark:text-gray-400">
        <FormattedMessage
          id="pages.dashboard.api_key_statistics.modal.labels.delete_link"
          defaultMessage="Are you sure you want to delete this link? This action is not reversible."
        />
      </p>
      <div>
        <div className="mb-2 block">
          <Label htmlFor="confirmation" value={label} />
        </div>
        <TextInput
          id="confirmation"
          placeholder={placeholder}
          color={formik.touched.confirmation && formik.errors.confirmation ? 'failure' : undefined}
          helperText={formik.touched.confirmation && formik.errors.confirmation ? <span className="font-medium">{formik.errors.confirmation}</span> : null}
          {...formik.getFieldProps('confirmation')}
          autoComplete="off"
        />
      </div>
      <div className="flex justify-center gap-4">
        <Button color="gray" onClick={handleOnCancel}>
          <HiX className="mr-2 h-5 w-5" />
          <FormattedMessage id="common.buttons.cancel" defaultMessage="Delete" />
        </Button>
        <Button color="success" type="submit">
          <HiCheck className="mr-2 h-5 w-5" />
          <FormattedMessage id="common.buttons.delete" defaultMessage="Delete" />
        </Button>
      </div>
    </form>
  );
}
