import { createAction } from '@reduxjs/toolkit';

// export const loadMyInformation = createAction('getMyInformation', function prepare(userId: string) {
//   return {
//     payload: {
//       userId,
//     },
//   };
// });

export const loadMyInformation = createAction('user/getMyInformation');

export const loadMyApiKeys = createAction('user/getMyApiKeys');
