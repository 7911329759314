import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const deleteLinkValidationSchema = (intl: IntlShape) =>
  Yup.object({
    confirmation: Yup.string()
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      )
      .equals(
        [
          intl.formatMessage({
            id: 'pages.dashboard.api_key_statistics.modal.placeholders.delete',
            defaultMessage: 'delete',
          }),
        ],
        intl.formatMessage({
          id: 'pages.dashboard.api_key_statistics.modal.errors.delete',
          defaultMessage: 'You must confirm by typing "delete"',
        }),
      ),
  });
