import { Button, Card } from 'flowbite-react';
import { MouseEventHandler } from 'react';
import { HiChartBar } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { ApiKey } from '../models/api-key';
import ShrtApiKeyQuickStatistics from './statistics/api-keys/ApiKeyQuickStatistic';

type ShrtApiKeyCardStatisticsProps = {
  active?: boolean;
  apiKey: ApiKey;
  onShowMoreHandler: (key: ApiKey) => void;
};

export default function ShrtApiKeyStatisticsCard({ active, apiKey, onShowMoreHandler }: ShrtApiKeyCardStatisticsProps) {
  const handleShowMore: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onShowMoreHandler(apiKey);
  };

  return (
    <Card className={`max-w-lg text-center${active ? ' border-cyan-600/[.55] border-2' : ''}`}>
      <h5 className="text-3xl font-bold text-gray-900 dark:text-white">{apiKey.alias}</h5>
      {/* <p className="text-xs text-gray-400 dark:text-gray-400 sm:text-lg">{apiKey.id}</p> */}
      <br />
      <p className="text-sm text-gray-400 dark:text-white sm:text-lg">
        <FormattedMessage id="pages.dashboard.labels.quick_statistics.title" defaultMessage="Links" />
      </p>
      {/* <div className="items-center justify-center space-y-4 sm:flex sm:space-x-4 sm:space-y-0"> */}
      <div className="grid grid-cols-3 divide-x">
        <ShrtApiKeyQuickStatistics
          fontColor="text-green-500"
          labelDefaultMessage="Total"
          labelId="pages.dashboard.labels.quick_statistics.links"
          value={apiKey.statistics?.totalLinks}
        />
        <ShrtApiKeyQuickStatistics
          fontColor="text-purple-500"
          labelDefaultMessage="Opened"
          labelId="pages.dashboard.labels.quick_statistics.links_opened"
          value={apiKey.statistics?.totalLinksOpened}
        />
        <ShrtApiKeyQuickStatistics
          fontColor="text-blue-500"
          labelDefaultMessage="Clicked"
          labelId="pages.dashboard.labels.quick_statistics.links_clicked"
          value={apiKey.statistics?.totalLinksClicked}
        />
      </div>
      <Button outline onClick={handleShowMore}>
        <HiChartBar className="mr-2 h-5 w-5" />
        <FormattedMessage id="pages.dashboard.buttons.show_more" defaultMessage="More statistics" />
      </Button>
    </Card>
  );
}
