import { Spinner, SpinnerSizes } from 'flowbite-react';

interface ShrtSpinnerProps {
  size?: keyof SpinnerSizes | undefined;
}

export default function ShrtSpinner({ size = 'xl' }: ShrtSpinnerProps) {
  return (
    <div className="flex justify-center">
      <div className="flex flex-wrap gap-2">
        <div className="text-center">
          <Spinner size={size} aria-label="loading" />
        </div>
      </div>
    </div>
  );
}
