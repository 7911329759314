import { Button, Label, TextInput } from 'flowbite-react';
import { useFormik } from 'formik';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreateApiKeyInput } from '../../../services/shortenify.service';
import { createApiKeyValidationSchema } from './modal-create_validation';

type CreateApiKeyModalProps = {
  cancel: () => void;
  confirm: (input: CreateApiKeyInput) => void;
};

export default function CreateApiKeyModal({ cancel, confirm }: CreateApiKeyModalProps) {
  const intl = useIntl();
  const aliasLabel = intl.formatMessage({ id: 'pages.settings.modal.labels.alias', defaultMessage: 'Alias:' });
  const tagLabel = intl.formatMessage({ id: 'pages.settings.modal.labels.tag', defaultMessage: 'Tag:' });

  const handleOnCancel = () => cancel();

  const formik = useFormik<CreateApiKeyInput>({
    initialValues: {
      alias: '',
      tag: '',
    },
    validationSchema: createApiKeyValidationSchema(intl),
    onSubmit: (values) => confirm(values),
  });

  return (
    <form className="space-y-6" onSubmit={formik.handleSubmit}>
      <div>
        <div className="mb-2 block">
          <Label htmlFor="alias" value={aliasLabel} />
        </div>
        <TextInput
          id="alias"
          placeholder="prod_api_key"
          color={formik.touched.alias && formik.errors.alias ? 'failure' : undefined}
          helperText={formik.touched.alias && formik.errors.alias ? <span className="font-medium">{formik.errors.alias}</span> : null}
          {...formik.getFieldProps('alias')}
        />
      </div>
      <div>
        <div className="mb-2 block">
          <Label htmlFor="tag" value={tagLabel} />
        </div>
        <TextInput
          id="tag"
          placeholder="testing_purpose"
          color={formik.touched.tag && formik.errors.tag ? 'failure' : undefined}
          helperText={formik.touched.tag && formik.errors.tag ? <span className="font-medium">{formik.errors.tag}</span> : null}
          {...formik.getFieldProps('tag')}
        />
      </div>
      <div className="flex justify-center gap-4">
        <Button color="gray" onClick={handleOnCancel}>
          <HiX className="mr-2 h-5 w-5" />
          <FormattedMessage id="pages.settings.modal.buttons.cancel" defaultMessage="Cancel" />
        </Button>
        <Button color="success" type="submit">
          <HiCheck className="mr-2 h-5 w-5" />
          <FormattedMessage id="pages.settings.modal.buttons.confirm" defaultMessage="Create" />
        </Button>
      </div>
    </form>
  );
}
