import { scaleLinear } from 'd3-scale';
import { Geography } from 'react-simple-maps';
import { LinkCountry } from '../../../../models/link';
import { GraphColors } from '../../../../utils/ColorUtils';

type ShrtLinkHeatMapCountryProps = {
  country?: LinkCountry;
  geo: any;
  max: number;
  onCountryEnterHandler: (country: LinkCountry) => void;
  onCountryLeaveHandler: () => void;
  selected?: boolean;
};

export default function ShrtLinkHeatMapCountry({
  country,
  geo,
  max,
  onCountryEnterHandler,
  onCountryLeaveHandler,
  selected = false,
}: ShrtLinkHeatMapCountryProps) {
  const colorScale = scaleLinear<string>().domain([0, max]).range(['#ffedea', '#ff5233']);

  const handleMouseEnter = (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
    event.preventDefault();
    if (country) {
      onCountryEnterHandler(country);
    }
  };

  const handleMouseLeave = (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
    event.preventDefault();
    if (country) {
      onCountryLeaveHandler();
    }
  };

  return (
    <Geography
      key={geo.rsmKey}
      geography={geo}
      fill={country ? (selected ? GraphColors.SKY.HEX : colorScale(country?.opened)) : '#F5F4F6'}
      stroke="#f8fafc"
      strokeWidth={0.75}
      id={country?.country}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
}
