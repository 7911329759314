import { ConsoleLogger } from 'aws-amplify/utils';
import { Breadcrumb } from 'flowbite-react';
import { HiHome } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BREADCRUMB_ROUTES, Route } from '../../config/routes';
import { selectedApiKeySelector } from '../../redux/reducers/api-keys/api-keys-reducer';
import { extractBreadcrumbItems } from '../../utils/UrlUtils';

const logger = new ConsoleLogger('ShrtBreadcrumb', process.env.REACT_APP_LOG_LEVEL);

type ShrtBreadcrumbProps = {
  location: string;
};

export function ShrtBreadcrumb({ location }: ShrtBreadcrumbProps) {
  const selectedApiKey = useSelector(selectedApiKeySelector);
  const navigate = useNavigate();
  const hierarchy = extractBreadcrumbItems(location, selectedApiKey);
  logger.debug('breadcrumb: ', hierarchy);

  return (
    <div className="bg-white dark:bg-gray-800 px-4 pt-8">
      <Breadcrumb aria-label="Default breadcrumb example">
        {hierarchy?.map((item) => (
          <Breadcrumb.Item href="#" onClick={() => navigate(item.value)} icon={item.name === 'home' ? HiHome : undefined} key={item.name}>
            {item.name === Route.Home && !BREADCRUMB_ROUTES.includes(item.name) ? (
              item.value
            ) : selectedApiKey?.alias === item.name ? (
              item.name
            ) : (
              <FormattedMessage id={`breadcrumb.${item.name}`} defaultMessage={item.name} />
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
}
