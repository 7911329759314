import { ConsoleLogger } from 'aws-amplify/utils';
import { APP_LOG_LEVEL } from './ConfigUtils';

export const LogTypes = {
  DEBUG: 'DEBUG',
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARN: 'WARN',
  VERBOSE: 'VERBOSE',
  NONE: 'NONE',
};

// export const LogLevel = keyof LogType;

export type LogLevel = keyof typeof LogTypes;

export const getConsoleLogger = (name: string) => new ConsoleLogger(name, APP_LOG_LEVEL);
