import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiKey } from '../../../models/api-key';
import { RootState } from '../../root';

// Define a type for the slice state
interface ApiKeysState {
  allApiKeys: ApiKey[];
  apiKeysById?: { [key: string]: ApiKey };
  selectedApiKey: ApiKey | null;
  ui: {
    error?: string;
    loading: boolean;
  };
}

// Define the initial state using that type
const initialState: ApiKeysState = {
  allApiKeys: [],
  apiKeysById: undefined,
  ui: {
    error: undefined,
    loading: false,
  },
  selectedApiKey: null,
};

export const apiKeysSlice = createSlice({
  name: 'apiKeys',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    createdApiKey: (state, action: PayloadAction<ApiKey>) => {
      console.info('payload received: ', action);
      state.allApiKeys = [...state.allApiKeys, action.payload];
      if (state.apiKeysById) {
        state.apiKeysById[action.payload.id] = action.payload;
      }
      state.ui.loading = false;
      state.ui.error = undefined;
    },
    deletedApiKey: (state, action: PayloadAction<string>) => {
      state.allApiKeys = state.allApiKeys.filter((key) => key.id !== action.payload);
      state.apiKeysById = state.allApiKeys.reduce((acc: { [key: string]: ApiKey }, apiKey) => {
        acc[apiKey.id] = apiKey;
        return acc;
      }, {});
      state.ui.loading = false;
      state.ui.error = undefined;
    },
    selectApiKey: (state, action: PayloadAction<ApiKey>) => {
      state.selectedApiKey = action.payload;
      state.ui.error = undefined;
    },
    setApiKeys: (state, action: PayloadAction<ApiKey[]>) => {
      state.allApiKeys = action.payload;
      state.apiKeysById = action.payload.reduce((acc: { [key: string]: ApiKey }, apiKey) => {
        acc[apiKey.id] = apiKey;
        return acc;
      }, {});
      state.ui.loading = false;
    },
    setApiKeyError: (state, action: PayloadAction<string>) => {
      state.ui.error = action.payload;
      state.ui.loading = false;
    },
    setApiKeysLoading: (state) => {
      state.ui.loading = true;
      state.ui.error = undefined;
    },
  },
});

export const { createdApiKey, deletedApiKey, selectApiKey, setApiKeys, setApiKeyError, setApiKeysLoading } = apiKeysSlice.actions;
export const selectedApiKeySelector = (state: RootState) => state.apiKeys.selectedApiKey;
export const apiKeysSelector = (state: RootState) => state.apiKeys.allApiKeys;
export const apiKeysByIdSelector = (state: RootState) => state.apiKeys.apiKeysById;
// UI
export const apiKeyErrorSelector = (state: RootState) => state.apiKeys.ui.error;
export const loadingApiKeysSelector = (state: RootState) => state.apiKeys.ui.loading;
