import { ConsoleLogger } from 'aws-amplify/utils';
import { Button, Label, Select, TextInput } from 'flowbite-react';
import { Formik } from 'formik';
import { HiCreditCard } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import ShrtBanner from '../../../components/Banner';
import { PricingPlan, User } from '../../../models/user';
import { timeTo } from '../../../utils/DateUtils';
import { billingFreeTierValidationSchema } from '../page_validation';

type ShrtFreeTierFormProps = {
  user: User;
  onProceedPayment: (pricingPlan: PricingPlan) => void;
  pricingPlan?: PricingPlan;
};

const logger = new ConsoleLogger('ShrtFreeTierForm', process.env.REACT_APP_LOG_LEVEL);

export default function ShrtFreeTierForm({ onProceedPayment, pricingPlan, user }: ShrtFreeTierFormProps) {
  const intl = useIntl();
  logger.debug('free tier with user information', user);

  const handleSubmit = (values: any) => {
    logger.debug('values: ', values);
    onProceedPayment(values.pricingPlan!);
  };

  return (
    <Formik
      initialValues={{
        name: user.billing.name?.length === 0 ? `${user.firstName} ${user.lastName}` : user.billing.name,
        pricingPlan: pricingPlan ?? user.billing.pricingPlan,
      }}
      onSubmit={handleSubmit}
      validationSchema={billingFreeTierValidationSchema(intl)}
    >
      {(props) => (
        <div>
          {user.billing.expirationDate && (
            <div className="sm:mx-auto sm:w-full max-w-screen-sm">
              <ShrtBanner>
                <FormattedMessage
                  id="pages.billing.labels.chose_plan"
                  defaultMessage="You're currently on Free Tier which expires in {expirationDate, plural, one {# day} other {# days}}, just chose a plan below and click on save. You will be redirect to the payment page."
                  values={{ expirationDate: timeTo(user.billing.expirationDate) }}
                />
              </ShrtBanner>
            </div>
          )}
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={props.handleSubmit}>
              <div>
                <Label htmlFor="pricingPlan" value={intl.formatMessage({ id: 'pages.billing.labels.plan', defaultMessage: 'Pricing plan' })} />
                <div className="mt-2">
                  <Select {...props.getFieldProps('pricingPlan')}>
                    <option value={PricingPlan.Basic}>
                      <FormattedMessage id="common.pricing_plans.basic" defaultMessage="Basic plan" />
                    </option>
                    <option value={PricingPlan.Essential}>
                      <FormattedMessage id="common.pricing_plans.essential" defaultMessage="Essential plan" />
                    </option>
                    <option value={PricingPlan.Premium}>
                      <FormattedMessage id="common.pricing_plans.premium" defaultMessage="Premium plan" />
                    </option>
                  </Select>
                </div>
              </div>
              <div>
                <Label htmlFor="name" value={intl.formatMessage({ id: 'pages.billing.labels.name', defaultMessage: 'Full name' })} />
                <div className="mt-2">
                  <TextInput
                    id="name"
                    type="text"
                    autoComplete="billing given-name"
                    placeholder={intl.formatMessage({
                      id: 'pages.billing.placeholders.name',
                      defaultMessage: 'Type the full name of the payer',
                    })}
                    {...props.getFieldProps('name')}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 mt-10">
                <Button className="flex" type="submit" outline pill disabled={props.isSubmitting}>
                  <HiCreditCard className="mr-2 h-5 w-5" />
                  <FormattedMessage id="pages.billing.buttons.pay" defaultMessage="Proceed to payment" />
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Formik>
  );
}
