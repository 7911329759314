import { FormattedMessage } from 'react-intl';

type ShrtLinkQRCodeProps = {
  fontColor: string;
  labelDefaultMessage: string;
  labelId: string;
  value: string;
};

export default function ShrtLinkQRCode({ fontColor, labelDefaultMessage, labelId, value }: ShrtLinkQRCodeProps) {
  return (
    <div className="text-center">
      <div className={`text-md ${fontColor} font-semibold`}>
        <FormattedMessage id={labelId} defaultMessage={labelDefaultMessage} />
      </div>
      <div className="flex justify-center">
        <div className="max-w-sm">
          <img src={value} alt="qr_code"></img>
        </div>
      </div>
    </div>
  );
}
