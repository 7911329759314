import { Avatar, DarkThemeToggle, Dropdown, Navbar, useThemeMode } from 'flowbite-react';
import { HTMLAttributeAnchorTarget, useEffect, useState } from 'react';
import { HiCreditCard, HiOutlineUserCircle } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import darkLogo from '../assets/dark_logo_no_background.png';
import logo from '../assets/logo_no_background.png';
import { Route } from '../config/routes';
import { myInformationSelector } from '../redux/reducers/user/user-reducer';
import { signOutUser } from '../services/authentication.service';
import { fullname } from '../services/user.service';
import { currentTheme } from '../utils/ColorUtils';

const activeClass = 'bg-cyan-700 text-white dark:text-white md:bg-transparent md:text-cyan-700';
const defaultClass =
  'border-b border-gray-100  text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white';

function ShrtNavbarLink({ children, target, to }: { children: React.ReactNode; target?: HTMLAttributeAnchorTarget; to: string }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;
  return (
    <NavLink to={to} target={target} rel={rel}>
      {({ isActive }) => <div className={isActive ? activeClass : defaultClass}>{children}</div>}
    </NavLink>
  );
}

export default function ShrtNavbar() {
  const navigate = useNavigate();
  const user = useSelector(myInformationSelector);
  const [currentMode, setCurrentMode] = useState<'dark' | 'light'>();
  const { mode } = useThemeMode();

  useEffect(() => {
    setCurrentMode(mode === 'auto' ? currentTheme() : mode);
  }, [mode, setCurrentMode]);

  const handleOnClickBilling = async () => navigate(Route.AccountBilling, { state: { plan: user?.billing.pricingPlan } });

  const handleOnClickMyAccount = async () => navigate(Route.AccountSettings);

  const handleOnClickSignOut = async () => await signOutUser();

  const getLogo = (mode?: 'dark' | 'light') => {
    if (!mode || mode === 'dark') {
      return <img src={darkLogo} className="mr-3 h-6 sm:h-9" alt="Shortenify" />;
    }
    return <img src={logo} className="mr-3 h-6 sm:h-9" alt="Shortenify" />;
  };

  return (
    <Navbar fluid>
      <Navbar.Brand as={NavLink} href={Route.Home}>
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          {getLogo(currentMode) /* <img src={logo} className="mr-3 h-6 sm:h-9" alt="Shortenify" /> */}
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <DarkThemeToggle />
        <Dropdown arrowIcon={false} inline label={<Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded />}>
          <Dropdown.Header>
            <span className="block text-sm">{user && fullname(user)}</span>
            <span className="block truncate text-sm font-medium">{user?.email}</span>
          </Dropdown.Header>
          <Dropdown.Item onClick={handleOnClickMyAccount}>
            <HiOutlineUserCircle />
            &nbsp;
            <FormattedMessage id="navbar.menu.account.my_account" defaultMessage="My account" />
          </Dropdown.Item>
          <Dropdown.Item onClick={handleOnClickBilling}>
            <HiCreditCard />
            &nbsp;
            <FormattedMessage id="navbar.menu.account.billing" defaultMessage="Billing" />
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleOnClickSignOut}>
            <FormattedMessage id="navbar.menu.account.sign_out" defaultMessage="Sign out" />
          </Dropdown.Item>
        </Dropdown>
      </div>
      <Navbar.Collapse>
        <ShrtNavbarLink to={Route.Dashboard}>
          <FormattedMessage id="navbar.menu.items.dashboard" defaultMessage="Dashboard" />
        </ShrtNavbarLink>
        <ShrtNavbarLink to={Route.Graphiql}>
          <FormattedMessage id="navbar.menu.items.graphiql" defaultMessage="GraphiQL" />
        </ShrtNavbarLink>
        <ShrtNavbarLink to={Route.Keys}>
          <FormattedMessage id="navbar.menu.items.keys" defaultMessage="API keys" />
        </ShrtNavbarLink>
        <ShrtNavbarLink to={process.env.REACT_APP_GRAPHQL_PUBLIC_API!} target="_blank">
          <FormattedMessage id="navbar.menu.items.documentation" defaultMessage="Documentation" />
        </ShrtNavbarLink>
      </Navbar.Collapse>
    </Navbar>
  );
}
