const config = {
  region: process.env.REACT_APP_GRAPHQL_REGION,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_CLIENT_ID!,
  userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID!,
  graphql: {
    endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT!,
    defaultAuthenticationType:
      process.env.REACT_APP_GRAPHQL_DEFAULT_AUTHENTICATION_TYPE!,
  },
};

export default config;
