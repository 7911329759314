import { GraphQLResult } from 'aws-amplify/api';
import { ConsoleLogger } from 'aws-amplify/utils';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { ApiKey } from '../../models/api-key';
import { User } from '../../models/user';
import { getMyApiKeys, getMyInformation } from '../../services/shortenify.service';
import { loadMyApiKeys, loadMyInformation } from '../actions/userActions';
import { setApiKeyError, setApiKeys, setApiKeysLoading } from '../reducers/api-keys/api-keys-reducer';
import { showNotification } from '../reducers/notifications/notification-reducer';
import { setMyInformation } from '../reducers/user/user-reducer';

const logger = new ConsoleLogger('userSagas', process.env.REACT_APP_LOG_LEVEL);

export function* loadMyInformationWatcher() {
  yield takeLatest(loadMyInformation.type, loadMyInformationWorker);
}

export function* loadMyInformationWorker() {
  logger.log(`Taking ${loadMyInformation.type}`);
  try {
    const response: GraphQLResult<{ myInformation: User }> = yield call(getMyInformation);
    logger.debug('getMyInformation', response.data.myInformation);
    yield put(setMyInformation(response.data.myInformation));
    yield put(loadMyApiKeys());
    yield put(
      showNotification({
        defaultLabel: 'Welcome back {name}',
        labelId: 'common.notifications.welcome_back',
        type: 'info',
        values: { name: response.data.myInformation.firstName },
      }),
    );
  } catch (e) {
    logger.error('Error loading user information', e);
    yield put(
      showNotification({
        defaultLabel: 'An error occurred. Please try again later',
        labelId: 'common.notifications.error',
        type: 'error',
      }),
    );
  }
}

export function* loadMyApiKeysWatcher() {
  yield takeLatest(loadMyApiKeys.type, loadMyApiKeysWorker);
}

export function* loadMyApiKeysWorker() {
  logger.log(`Taking ${loadMyApiKeys.type}`);
  try {
    yield put(setApiKeysLoading());
    const response: GraphQLResult<{ myApiKeys: ApiKey[] }> = yield call(getMyApiKeys);
    yield put(setApiKeys(response.data.myApiKeys));
  } catch (e) {
    logger.error('Error loading API keys', e);
    yield put(setApiKeyError('Error loading API keys'));
  }
}

const userSagas = [fork(loadMyInformationWatcher), fork(loadMyApiKeysWatcher)];

export default userSagas;
