import { GraphQLResult, generateClient } from 'aws-amplify/api';
import { createApiKey } from '../graphql/mutations/createApiKey';
import { createLink } from '../graphql/mutations/createLink';
import { deleteApiKey } from '../graphql/mutations/deleteApiKey';
import { deleteLink } from '../graphql/mutations/deleteLink';
import { updateBillingInformation } from '../graphql/mutations/updateBillingInformation';
import { updateUserInformation } from '../graphql/mutations/updateUserInformation';
import { getLink } from '../graphql/queries/getLink';
import { getLinks } from '../graphql/queries/getLinks';
import { latestLinks } from '../graphql/queries/latestLinks';
import { myApiKeys } from '../graphql/queries/myApiKeys';
import { myInformation } from '../graphql/queries/myInformation';
import { ApiKey } from '../models/api-key';
import { Link, LinkConnection, LinkStatisticsPeriod, QrCodeType } from '../models/link';
import { PricingPlan, User } from '../models/user';

export const client = generateClient();

export type ApiInput = {
  apiKey: string;
};

export type CreateApiKeyInput = {
  alias: string;
  tag?: string;
};

export type DeleteApiKeyInput = {
  apiKey: string;
};

export type CreateLinkCredentialsInput = {
  username: string;
  password: string;
};

export type CreateLinkInput = ApiInput & {
  linkId: string;
  qrCodeType?: QrCodeType;
  credentials?: CreateLinkCredentialsInput;
};

export type DeleteLinkInput = {
  apiKey: string;
  linkId: string;
};

export type UpdateMyBillingInformationInput = {
  pricingPlan: PricingPlan;
};

export type UpdateMyInformationInput = {
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  street: string;
  zipCode: string;
};

export const getMyInformation = async () => client.graphql<User>({ query: myInformation }) as Promise<GraphQLResult<{ myInformation: User }>>;

export const getMyLink = async (apiKey: string, id: string, from?: string, to?: string, period?: LinkStatisticsPeriod) =>
  client.graphql<Link>({ query: getLink(apiKey, id, from, to, period) }) as Promise<GraphQLResult<{ getLink: Link }>>;

export const getMyLinks = async (apiKey: string, after?: string) =>
  client.graphql<LinkConnection>({ query: getLinks(apiKey, after) }) as Promise<GraphQLResult<{ getLinks: LinkConnection }>>;

export const getMyApiKeys = async (statistics = false) =>
  client.graphql<ApiKey[]>({ query: myApiKeys(statistics) }) as Promise<GraphQLResult<{ myApiKeys: ApiKey[] }>>;

export const createMyApiKey = async (input: CreateApiKeyInput) =>
  client.graphql<ApiKey>({
    query: createApiKey,
    variables: {
      input: {
        alias: input.alias,
        tag: input.tag,
      },
    },
  }) as Promise<GraphQLResult<{ createMyApiKey: ApiKey }>>;

export const deleteMyApiKey = async (input: DeleteApiKeyInput) =>
  client.graphql<ApiKey>({
    query: deleteApiKey,
    variables: {
      input: {
        apiKey: input.apiKey,
      },
    },
  }) as Promise<GraphQLResult<{ deleteApiKey: ApiKey }>>;

export const updateMyInformation = async (input: UpdateMyInformationInput) =>
  client.graphql<User>({
    query: updateUserInformation,
    variables: {
      input: {
        city: input.city,
        country: input.country,
        firstName: input.firstName,
        lastName: input.lastName,
        street: input.street,
        zipCode: input.zipCode,
      },
    },
  }) as Promise<GraphQLResult<{ updateMyInformation: User }>>;

export const updateMyBillingInformation = async (input: UpdateMyBillingInformationInput) =>
  client.graphql<User>({
    query: updateBillingInformation,
    variables: {
      input: {
        pricingPlan: input.pricingPlan,
      },
    },
  }) as Promise<GraphQLResult<{ updateMyBillingInformation: User }>>;

export const getLatestLinks = async (apiKey: string) =>
  client.graphql<{ hotLinks: { items: Link[] }; lastLinks: { items: Link[] } }>({
    query: latestLinks(apiKey),
  }) as Promise<GraphQLResult<{ hotLinks: { items: Link[] }; lastLinks: { items: Link[] } }>>;

export const createNewLink = async (input: CreateLinkInput) =>
  client.graphql<Link>({
    query: createLink,
    variables: {
      input,
    },
  }) as Promise<GraphQLResult<{ createLink: Link }>>;

export const deleteMyLink = async (input: DeleteLinkInput) =>
  client.graphql<Link>({
    query: deleteLink,
    variables: {
      input,
    },
  }) as Promise<GraphQLResult<{ deleteLink: Link }>>;
