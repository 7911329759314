import { Table } from 'flowbite-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LinkCountry } from '../../../../models/link';
import { GraphColors } from '../../../../utils/ColorUtils';

const DEFAULT_COLOR = 'text-gray-900 ';

type ShrtLinkRowProps = {
  hoverStartHandler?: (linkCountry: LinkCountry) => void;
  hoverEndHandler?: (linkCountry: LinkCountry) => void;
  linkCountry: LinkCountry;
};

export default function ShrtLinkCountryRow({ hoverStartHandler, hoverEndHandler, linkCountry }: ShrtLinkRowProps) {
  const [textColor, setTextColor] = useState<string>(DEFAULT_COLOR);

  const handleHoverStart: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    setTextColor(GraphColors.SKY.CLASS);
    hoverStartHandler && hoverStartHandler(linkCountry);
  };
  const handleHoverEnd: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    setTextColor(DEFAULT_COLOR);
    hoverEndHandler && hoverEndHandler(linkCountry);
  };

  return (
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={linkCountry.country}>
      <Table.Cell className={`whitespace-nowrap font-medium dark:text-white ${textColor}`}>
        <span onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd}>
          <FormattedMessage id={`countries.${linkCountry.country}`} defaultMessage={linkCountry.country} />
        </span>
      </Table.Cell>
      <Table.Cell className={`dark:text-white ${textColor}`}>{linkCountry.opened}</Table.Cell>
    </Table.Row>
  );
}
