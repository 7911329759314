import { Authenticator } from '@aws-amplify/ui-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'chart.js/auto';
import { Flowbite } from 'flowbite-react';
import { CustomFlowbiteTheme } from 'flowbite-react';
import type {} from 'flowbite-react';
import 'graphiql/graphiql.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import configureAmplify from './config/configure.amplify';
import { Route } from './config/routes';
import { language, messages } from './i18n/translate';
import './index.css';
import ShrtAccountSettings from './pages/account/page';
import ShrtAccountBilling from './pages/billing/page';
import ShrtApiKeyLinks from './pages/dashboard/api-key-statistics/all-links/page';
import ShrtLink from './pages/dashboard/api-key-statistics/link/page';
import ShrtApiKeyStatistics from './pages/dashboard/api-key-statistics/page';
import ShrtDashboard from './pages/dashboard/page';
import ShrtGraphiql from './pages/graphiql/page';
import ShrtSettings from './pages/settings/page';
import ShrtSignUp from './pages/sign-up/page';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { Colors, currentTheme } from './utils/ColorUtils';

const queryClient = new QueryClient();
const customTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary: Colors.PRIMARY,
    },
  },
  datepicker: {
    views: {
      days: {
        header: {
          title: 'h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-300',
        },
        items: {
          item: {
            disabled: 'text-gray-500 dark:text-gray-500',
          },
        },
      },
    },
  },
};

configureAmplify();
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <ShrtDashboard />,
      },
      {
        path: Route.Dashboard,
        element: <ShrtDashboard />,
        children: [{ path: Route.DashboardApiKey, element: <ShrtApiKeyStatistics /> }],
      },
      {
        path: Route.DashboardLinks,
        element: <ShrtApiKeyLinks />,
      },
      {
        path: Route.DashboardLink,
        element: <ShrtLink />,
      },
      // {
      //   path: `${Route.Dashboard}/:apiKey`,
      //   element: <ShrtApiKeyStatistics />,
      // },
      {
        path: Route.Graphiql,
        element: <ShrtGraphiql />,
      },
      {
        path: Route.SignUp,
        element: <ShrtSignUp />,
      },
      {
        path: Route.Keys,
        element: <ShrtSettings />,
      },
      {
        path: Route.AccountBilling,
        element: <ShrtAccountBilling />,
      },
      {
        path: Route.AccountSettings,
        element: <ShrtAccountSettings />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <div className="h-dvh">
      <Provider store={store}>
        <IntlProvider messages={messages} locale={language} defaultLocale="en">
          <Authenticator.Provider>
            <QueryClientProvider client={queryClient}>
              <Flowbite theme={{ mode: currentTheme(), theme: customTheme }}>
                <RouterProvider router={router} />
              </Flowbite>
            </QueryClientProvider>
          </Authenticator.Provider>
        </IntlProvider>
      </Provider>
    </div>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
