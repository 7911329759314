import { FormattedMessage } from 'react-intl';

type ShrtApiKeyQuickStatisticsProps = {
  fontColor: string;
  labelDefaultMessage: string;
  labelId: string;
  value?: number;
};

export default function ShrtApiKeyQuickStatistics({
  fontColor,
  labelDefaultMessage,
  labelId,
  value,
}: ShrtApiKeyQuickStatisticsProps) {
  return (
    <div>
      <div className={`text-sm ${fontColor} font-semibold`}>
        <FormattedMessage id={labelId} defaultMessage={labelDefaultMessage} />
        {/* <Tooltip content="Tooltip content">
          <HiOutlineInformationCircle />
        </Tooltip> */}
      </div>
      <div className={`mt-1 font-sans text-2xl ${fontColor} font-semibold`}>{value}</div>
    </div>
  );
}
