import { useQuery } from '@tanstack/react-query';
import { Alert } from 'flowbite-react';
import { FormattedMessage } from 'react-intl';
import { getConsoleLogger } from '../../utils/LogUtils';
import ShrtSpinner from './Spinner';

const logger = getConsoleLogger('ShrtQuery');

type UseShrtQueryProps = {
  action?: Function;
  children: (data: any) => JSX.Element; //React.ReactNode | JSX.Element | null | undefined;
  errorLabelId?: string;
  initialData?: any;
  placeholderData?: any;
  query: Function;
  queryKey: string;
  queryKeyCache?: string;
  queryKeyResponse?: string;
  refetchInterval?: number;
};

export default function ShrtQuery({
  action,
  children,
  errorLabelId,
  initialData,
  placeholderData,
  query,
  queryKey,
  queryKeyCache = queryKey,
  queryKeyResponse = queryKey,
  refetchInterval,
}: UseShrtQueryProps) {
  const { data, status } = useQuery({
    queryKey: [queryKeyCache],
    queryFn: async () => {
      const response = await query();
      const data = response.data;
      if (action) {
        action(data?.[queryKeyResponse]);
      }
      return data;
    },
    // ...(initialData ? { initialData: { [queryKeyResponse]: initialData } } : {}),
    placeholderData,
    refetchInterval,
  });

  if (status === 'pending') {
    logger.debug('rendering loading...');
    return <ShrtSpinner />;
  }

  if (status === 'error') {
    logger.debug('rendering error...');
    return (
      <div className="flex justify-center">
        <Alert color="warning">
          <FormattedMessage id={errorLabelId ? errorLabelId : 'common.generic_error_api'} defaultMessage="An error occurred. Please try again later..." />
        </Alert>
      </div>
    );
  }

  if (status === 'success' && data) {
    logger.debug('success data: ', data ?? initialData);
    return children(data ?? initialData);
  }

  return <ShrtSpinner />;
}
