import { Button, Table } from 'flowbite-react';
import { HiPlus } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';

type ShrtTableProps<T> = {
  items: T[];
  emptyData?: React.ReactNode;
  emptyDataLabel?: string;
  emptyDataDefaultLabel?: string;
  headLabels: React.ReactNode[];
  onCreateHandler?: () => void;
  onCreateLabel?: string;
  onCreateDefaultLabel?: string;
  renderRow: (item: T) => React.ReactNode;
};

export default function ShrtTable<T>({
  items = [],
  emptyData,
  emptyDataLabel,
  emptyDataDefaultLabel,
  headLabels,
  onCreateHandler,
  onCreateDefaultLabel,
  onCreateLabel,
  renderRow,
}: ShrtTableProps<T>) {
  const handleCreate: React.MouseEventHandler<HTMLButtonElement> = () => onCreateHandler && onCreateHandler();

  if (items.length === 0) {
    return (
      <div className="flex justify-center">
        {emptyData ? (
          emptyData
        ) : (
          <div className="flex justify-center text-center divide-y">
            <FormattedMessage
              id={emptyDataLabel ? emptyDataLabel : 'common.empty_data'}
              defaultMessage={emptyDataDefaultLabel ? emptyDataDefaultLabel : 'No data'}
            />
            {onCreateHandler && (
              <Button className="mt-5" outline onClick={handleCreate} type="submit">
                <HiPlus className="mr-2 h-5 w-5" />
                <FormattedMessage
                  id={onCreateLabel ? onCreateLabel : 'common.add_data_item'}
                  defaultMessage={onCreateDefaultLabel ? onCreateDefaultLabel : 'Add an entry'}
                />
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
  return (
    <Table striped>
      <Table.Head className="dark:text-white">
        {headLabels.map((label, index) => (
          <Table.HeadCell key={index}>{label}</Table.HeadCell>
        ))}
      </Table.Head>
      <Table.Body className="divide-y">{items.map((item) => renderRow(item))}</Table.Body>
    </Table>
  );
}
