import { Button, Label, TextInput, ToggleSwitch } from 'flowbite-react';
import { useFormik } from 'formik';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { createLinkValidationSchema } from './validation/create-modal_validation';

type CreateLinkModalProps = {
  cancel: () => void;
  confirm: (input: CreateLinkModalInput) => void;
  canGenerateQrCode: boolean;
  canProtectLink: boolean;
};

export type CreateLinkModalInput = {
  qrCode?: boolean;
  password?: string;
  protected?: boolean;
  url: string;
  username?: string;
};

export default function CreateLinkModal({ cancel, confirm, canGenerateQrCode, canProtectLink }: CreateLinkModalProps) {
  const intl = useIntl();
  const handleOnCancel = () => cancel();
  const urlLabel = intl.formatMessage({ id: 'pages.dashboard.api_key_statistics.modal.labels.create_link', defaultMessage: 'Enter the URL to shorten:' });
  const qrCodeLabel = intl.formatMessage({ id: 'pages.dashboard.api_key_statistics.modal.labels.qr_code', defaultMessage: 'Generate a QR code' });
  const protectLabel = intl.formatMessage({
    id: 'pages.dashboard.api_key_statistics.modal.labels.protected',
    defaultMessage: 'Protect your link with a password:',
  });
  const usernameLabel = intl.formatMessage({ id: 'pages.dashboard.api_key_statistics.modal.labels.username', defaultMessage: 'Username' });
  const passwordLabel = intl.formatMessage({ id: 'pages.dashboard.api_key_statistics.modal.labels.password', defaultMessage: 'Password' });
  const formik = useFormik<CreateLinkModalInput>({
    initialValues: {
      qrCode: false,
      password: '',
      protected: false,
      url: '',
      username: '',
    },
    validationSchema: createLinkValidationSchema(intl),
    onSubmit: async (values) =>
      confirm({ qrCode: values.qrCode, url: values.url, ...(values.protected ? { username: values.username, password: values.password } : {}) }),
  });

  return (
    <form className="space-y-6" onSubmit={formik.handleSubmit}>
      <div>
        <div className="mb-2 block">
          <Label htmlFor="url" value={urlLabel} />
        </div>
        <TextInput
          id="url"
          placeholder={intl.formatMessage({
            id: 'pages.dashboard.api_key_statistics.modal.placeholders.create_link.url',
            defaultMessage: 'Enter the URL to shorten',
          })}
          color={formik.touched.url && formik.errors.url ? 'failure' : undefined}
          helperText={formik.touched.url && formik.errors.url ? <span className="font-medium">{formik.errors.url}</span> : null}
          {...formik.getFieldProps('url')}
          autoComplete="off"
        />
      </div>
      {canGenerateQrCode && (
        <div>
          <div className="mb-2 block">
            <Label htmlFor="url" value={qrCodeLabel} />
          </div>
          <ToggleSwitch checked={formik.values.qrCode ?? false} onChange={(checked) => formik.setValues({ ...formik.values, qrCode: checked })} />
        </div>
      )}
      {canProtectLink && (
        <div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="protected" value={protectLabel} />
            </div>
            <ToggleSwitch checked={formik.values.protected ?? false} onChange={(checked) => formik.setValues({ ...formik.values, protected: checked })} />
          </div>
          {formik.values.protected && (
            <div className="mt-5">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="username" value={usernameLabel} />
                </div>
                <TextInput
                  id="username"
                  placeholder={intl.formatMessage({
                    id: 'pages.dashboard.api_key_statistics.modal.placeholders.create_link.username',
                    defaultMessage: 'my_top_secret_user',
                  })}
                  color={formik.touched.url && formik.errors.url ? 'failure' : undefined}
                  helperText={formik.touched.url && formik.errors.url ? <span className="font-medium">{formik.errors.url}</span> : null}
                  {...formik.getFieldProps('username')}
                  autoComplete="off"
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="password" value={passwordLabel} />
                </div>
                <TextInput
                  id="password"
                  placeholder={intl.formatMessage({
                    id: 'pages.dashboard.api_key_statistics.modal.placeholders.create_link.password',
                    defaultMessage: 'my_super_secret_password',
                  })}
                  color={formik.touched.url && formik.errors.url ? 'failure' : undefined}
                  helperText={formik.touched.url && formik.errors.url ? <span className="font-medium">{formik.errors.url}</span> : null}
                  {...formik.getFieldProps('password')}
                  autoComplete="off"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex justify-center gap-4">
        <Button color="gray" onClick={handleOnCancel}>
          <HiX className="mr-2 h-5 w-5" />
          <FormattedMessage id="common.buttons.cancel" defaultMessage="Delete" />
        </Button>
        <Button color="success" type="submit">
          <HiCheck className="mr-2 h-5 w-5" />
          <FormattedMessage id="common.buttons.create" defaultMessage="Create" />
        </Button>
      </div>
    </form>
  );
}
