import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

const COGNITO_SPECIAL_CHARACTERS = [
  '^',
  '$',
  '*',
  '.',
  '[',
  ']',
  '{',
  '}',
  '(',
  ')',
  '?',
  '"',
  '!',
  '@',
  '#',
  '%',
  '&',
  '/',
  '\\',
  ',',
  '>',
  '<',
  "'",
  ':',
  ';',
  '|',
  '_',
  '~',
  '`',
  '=',
  '+',
  '-',
];

export const signUpValidationSchema = (intl: IntlShape) =>
  Yup.object({
    email: Yup.string()
      .trim()
      .matches(
        /^\S+@\S+\.\S+$/,
        intl.formatMessage({
          id: 'common.forms.validation.email',
          defaultMessage: 'Not a valid email',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
    firstName: Yup.string()
      .trim()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters minimum',
          },
          { maximum: 15 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
    lastName: Yup.string()
      .trim()
      .min(
        3,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 3 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters minimum',
          },
          { maximum: 15 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
    password: Yup.string()
      .min(
        8,
        intl.formatMessage(
          {
            id: 'common.forms.validation.minimum',
            defaultMessage: '{minimum} characters minimum',
          },
          { minimum: 8 },
        ),
      )
      .max(
        15,
        intl.formatMessage(
          {
            id: 'common.forms.validation.maximum',
            defaultMessage: '{maximum} characters minimum',
          },
          { maximum: 15 },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      )
      .matches(
        /[A-Z]/,
        intl.formatMessage(
          {
            id: 'common.forms.validation.uppercase',
            defaultMessage: 'At least {value} uppercase character',
          },
          { value: 1 },
        ),
      )
      .matches(
        /[a-z]/,
        intl.formatMessage(
          {
            id: 'common.forms.validation.lowercase',
            defaultMessage: 'At least {value} lowercase character',
          },
          { value: 1 },
        ),
      )
      .matches(
        /\d/,
        intl.formatMessage(
          {
            id: 'common.forms.validation.digit',
            defaultMessage: 'At least {value} digit',
          },
          { value: 1 },
        ),
      )
      .matches(
        /[\^$*.[\]{}()?"!@#%&\/\\,\s><':;|_~`=+-]/,
        intl.formatMessage(
          {
            id: 'common.forms.validation.special',
            defaultMessage: 'At least {value} special character from: {list}',
          },
          { list: COGNITO_SPECIAL_CHARACTERS.join(' '), value: 1 },
        ),
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        intl.formatMessage({
          id: 'common.forms.validation.passwords',
          defaultMessage: 'Passwords do not match',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
  });
