import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

type ShrtLinkQuickStatisticsProps = {
  fontColor: string;
  labelDefaultMessage: string;
  labelId: string;
  value: number | string | ReactElement;
  url?: string;
};

export default function ShrtLinkQuickStatistics({ fontColor, labelDefaultMessage, labelId, value, url }: ShrtLinkQuickStatisticsProps) {
  return (
    <div className="text-center">
      <div className={`text-md ${fontColor} font-semibold`}>
        <FormattedMessage id={labelId} defaultMessage={labelDefaultMessage} />
      </div>
      <div className={`mt-1 font-sans text-2xl ${url ? 'text-cyan-600' : fontColor} font-semibold`}>
        {url ? (
          <Link to={url} target="_blank" rel="noopener noreferrer">
            {value}
          </Link>
        ) : (
          value
        )}
      </div>
    </div>
  );
}
