import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type ExternalLinkProps = {
  removeHttp?: boolean;
  to: string;
};

export default function ShrtExternalLink({ children, removeHttp = true, to }: PropsWithChildren<ExternalLinkProps>) {
  const cleanedLink = removeHttp ? to.replace(/https?:\/\//, '') : to;
  return (
    <Link to={to} target="_blank" rel="noopener noreferrer">
      {children ?? cleanedLink}
    </Link>
  );
}
